import { useState, useEffect } from 'react';

const desctopSize = 1000;

export const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = (e: Event) => {
      setWidth((e.target as Window).innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width < desctopSize ? false : true;
};