import { ImagesMap } from 'core/interfaces/images';

import PHONES_1X from './phones.webp';
import PHONES_2X from './phones@2x.webp';
import PHONES_3X from './phones@3x.webp';

export const IMAGES: ImagesMap = {
  PHONES: {
    src: PHONES_1X,
    srcSet: `${PHONES_1X}, ${PHONES_2X} 2x, ${PHONES_3X} 3x`,
  },
};