import React from 'react';
import classNames from 'classnames';
import queryString from 'query-string';

import classes from './style.module.scss';

interface Props {
  text: string;
  className?: string;
}

export const SubButton: React.FC<Props> = ({ text, className }) => {
  const campaign = window.location.pathname.split('/')[2] || 'main-page';
  return (
    <a
      href={
        queryString.stringifyUrl({
          url: process.env.REACT_APP_SUB_URL!,
          query: { campaign_name: campaign, campaign_id: campaign }
        })
      }
      className={classNames(classes.button, className)}>
      {text}
    </a>
  );
}

