import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { PageWrapper } from 'src/components/page-wrapper';

import { Header } from 'src/sections/header';
import { Intro } from 'src/sections/intro/main-intro';
import { Features } from 'src/sections/features/main';
import { FeedbacksMain } from 'src/sections/feedbacks/main';
import { GetExpert } from 'src/sections/get-expert';
import { HowItWorks } from 'src/sections/how-it-works';
import { FaqMain } from 'src/sections/faq/main';
import { AboutMain } from 'src/sections/about/main';
import { Footer } from 'src/sections/footer';

import { connectHelpdeskWidget } from './utils';

import './index.css';

export const Page: React.FC = () => {
  const { showSupportWidget } = useRemoteConfig();

  useEffect(() => {
    let timer: any = null;
    if (showSupportWidget) {
      timer = setTimeout(() => {
        connectHelpdeskWidget()
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [showSupportWidget]);

  return (
    <>
      <Helmet>
        <title>Astroline - Astrology & Psychic Readings Online, Birth Сhart Analysis, Daily Predictions</title>
        <meta property="og:title"
          content="Astrology & Psychic Readings Online, Birth Сhart Analysis, Daily Predictions — Astroline" />
        <meta name="description"
          content="Astroline — the best app for astrology readings online: Palm Reading, Birth Chart, Love Compatibility, Daily Horoscope, and more. Your astrological journey awaits!" />
        <meta property="og:description"
          content="Astroline — the best app for astrology readings online: Palm Reading, Birth Chart, Love Compatibility, Daily Horoscope, and more. Your astrological journey awaits!" />
      </Helmet>
      <PageWrapper>
        <Header />
        <Intro />
        <Features />
        <FeedbacksMain />
        <GetExpert />
        <HowItWorks />
        <FaqMain />
        <AboutMain />
        <Footer />
      </PageWrapper>
    </>
  );
};


