import _map from 'lodash/map';
import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { SubButton } from '../components/sub-button';

import { Insight } from './components/insight';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'sections.get_expert';

export const GetExpert: React.FC = () => {
  const content = t(`${tKey}.insights`, { returnObjects: true }) as any;

  return (
    <Section className={classes.container}>
      <Title level='h2' className={classes.title}>{t(`${tKey}.title`)}</Title>
      <main>
        <img src={IMAGES.PHONES.src} srcSet={IMAGES.PHONES.srcSet} alt="phones" />
        <div className={classes.content}>
          {_map(content, (v, k) => (
            <Insight text={v} key={k} />
          ))}
          <SubButton text={t(`sections.button_text`)} className={classes.sub_button}/>
        </div>
      </main>
    </Section>
  )
}