import React from 'react';

import ZodiacSign from 'astroline-web/src/screens/subscription/sections/compatibility-report/components/zodiac-sign';

import { ELEMENTS } from 'astroline-web/src/screens/subscription/sections/personalised_plan/data';

import { OverlayTextBlock } from 'src/sections/components/overlay-text-block';

import IMAGES from './images';

import classes from './style.module.scss'

interface Props {
  title: string;
  text: React.ReactNode;
  overlay_text: string;
  userSign: string;
  crushSign: string;
}

export const Result: React.FC<Props> = ({ title, text, overlay_text, userSign, crushSign }) => {
  return (
    <>
      <div className={classes.results}>
        <ZodiacSign
          zodiacName={userSign}
          elementCode={ELEMENTS[userSign]}
          isUserSign
        />
        <div className={classes.middle_block}>
          <span className={classes.percent}>90%</span>
          <img src={IMAGES.WAVES.src} srcSet={IMAGES.WAVES.srcSet} className={classes.img} alt="waves" />
        </div>
        <ZodiacSign
          zodiacName={crushSign}
          elementCode={ELEMENTS[crushSign]}
          isUserSign={false}
        />
      </div>
      <div className={classes.overlay}>
        <div className={classes.waves_bg} />
        <p className={classes.title}>{title}</p>
        <p className={classes.text}>{text}</p>
        <OverlayTextBlock text={overlay_text}/>
      </div>
    </>
  )
}