import React from 'react';

import { t } from '@web-solutions/module-localization';

import PageIntro from '../page-intro';

import { IMAGES } from './images';

const tKey = 'sections.palm_reading_intro';

const PalmReadingIntro = () => {
  return (
    <PageIntro title={t(`${tKey}.title`)} text={t(`${tKey}.text`)} image={IMAGES.PALM} />
  );
};

export default PalmReadingIntro;
