import React from 'react';
import classNames from 'classnames';

import Flare from './flare';

import classes from './style.module.scss';

import { ReactComponent as IconArrow } from './icons/right-arrow.svg';

interface Props {
  id?: string;
  onClick?: (x: any) => void;
  title: string | React.ReactElement;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  flare?: boolean;
  titleClassName?: string;
  iconRight?: boolean;
}

const Button: React.FC<Props> = ({
  id,
  onClick,
  title,
  disabled = false,
  className = null,
  type = 'button',
  flare = false,
  titleClassName = null,
  iconRight = false,
}) => (
  <button
    id={id}
    type={type}
    onClick={onClick}
    className={classNames(classes.button, className)}
    disabled={disabled}
  >
    <div className={classNames(classes.button__title, titleClassName)}>
      <span>{title}</span>
    </div>
    {iconRight && <IconArrow className={classes['button__right-icon']} />}
    {!disabled && flare ? <Flare /> : null}
  </button>
);

export default Button;
