import { ImagesMap } from 'core/interfaces/images';

import LOGO_ASTROLINE from './logo.webp';
import LOGO_ASTROLINE_2x from './logo@2x.webp';
import LOGO_ASTROLINE_3x from './logo@3x.webp';

const IMAGES: ImagesMap = {
  LOGO: {
    src: LOGO_ASTROLINE,
    srcSet: `${LOGO_ASTROLINE}, ${LOGO_ASTROLINE_2x} 2x, ${LOGO_ASTROLINE_3x} 3x`,
  },
};

export default IMAGES;