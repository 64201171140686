/* eslint-disable no-sequences */
//@ts-nocheck

export const connectHelpdeskWidget = () => {
  window.__ow = window.__ow || {};

  window.__ow.organizationId = "de087c6f-ae4d-4e54-aa5c-cb36ba51d6aa";
  window.__ow.integration_name = "manual_settings";
  window.__ow.product_name = "helpdesk";

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[OpenWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.openwidget.com/openwidget.js", t.head.appendChild(n) } }; !n.__ow.asyncInit && e.init(), n.OpenWidget = n.OpenWidget || e }(window, document, [].slice))
}