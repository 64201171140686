import { ImagesMap } from "core/interfaces/images";

import ANALYSIS_1X from './analysis.webp';
import ANALYSIS_2X from './analysis@2x.webp';
import ANALYSIS_3X from './analysis@3x.webp';

import INSIGHTS_1X from './insights.webp';
import INSIGHTS_2X from './insights@2x.webp';
import INSIGHTS_3X from './insights@3x.webp';

import INTERPRETATIONS_1X from './interpretations.webp';
import INTERPRETATIONS_2X from './interpretations@2x.webp';
import INTERPRETATIONS_3X from './interpretations@3x.webp';

import WISDOM_1X from './wisdom.webp'
import WISDOM_2X from './wisdom@2x.webp';
import WISDOM_3X from './wisdom@3x.webp';


export const IMAGES: ImagesMap = {
  ANALYSIS: {
    src: ANALYSIS_1X,
    srcSet: `${ANALYSIS_1X}, ${ANALYSIS_2X} 2x, ${ANALYSIS_3X} 3x`
  },
  INSIGHTS: {
    src: INSIGHTS_1X,
    srcSet: `${INSIGHTS_1X}, ${INSIGHTS_2X} 2x, ${INSIGHTS_3X} 3x`
  },
  INTERPRETATIONS: {
    src: INTERPRETATIONS_1X,
    srcSet: `${INTERPRETATIONS_1X}, ${INTERPRETATIONS_2X} 2x, ${INTERPRETATIONS_3X} 3x`
  },
  WISDOM: {
    src: WISDOM_1X,
    srcSet: `${WISDOM_1X}, ${WISDOM_2X} 2x, ${WISDOM_3X} 3x`
  },
}