import React from 'react';
import { Link, To } from 'react-router-dom';

import { ImageSource } from 'core/interfaces/images';

import { Block, Title } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  title: string;
  text: string;
  image: ImageSource;
  link?: React.ReactNode;
  path?: To,
  onChapterClick?: () => void
}

const Chapter: React.FC<Props> = ({ title, text, image, link, path = '', onChapterClick }) => {
  const content = (
    <div className={classes.wrap}>
      <img
        src={image.src}
        srcSet={image.srcSet}
        alt={title}
        className={classes.img}
      />
      <div className={classes.content}>
        <Title level='h3' className={classes.title}>{title}</Title>
        <p className={classes.text}>{text}</p>
        {link ? <div className={classes.link}>{link}</div> : <></>}
      </div>
    </div>
  );
  return (
    <Block className={classes.chapter} onClick={onChapterClick}>
      {path ? (
        <Link to={path} className={classes.wrap}>
          {content}
        </Link>
      ) : (
        content
      )}
    </Block>
  );
};

export default Chapter;
