import React from 'react';
import { Helmet } from 'react-helmet';

import TarotIntro from 'src/sections/intro/tarot';
import { FaqTarot } from 'src/sections/faq/tarot';
import { AboutTarot } from 'src/sections/about/tarot';
import { FeedbacksTarot } from 'src/sections/feedbacks/tarot';
import { Features } from 'src/sections/features/tarot';
import { Header } from 'src/sections/header/';
import { Tarot } from 'src/sections/tarot';
import { Footer } from 'src/sections/footer';

import { PageWrapper } from 'src/components/page-wrapper';

import classes from './style.module.scss';

export const TarotPage: React.FC = () => (
  <>
    <Helmet>
      <title>Online Tarot Reading - Let the Cards Answer Your Burning Questions 🎴</title>
      <meta property="og:title"
          content="Online Tarot Reading - Let the Cards Answer Your Burning Questions 🎴" />
      <meta name="description" content="Searching for answers to life's big questions? Let the ancient cards reveal what awaits you in love, health, and career! Try Astroline’s online tarot reading today." />
      <meta property="og:description" content="Searching for answers to life's big questions? Let the ancient cards reveal what awaits you in love, health, and career! Try Astroline’s online tarot reading today." />
    </Helmet>
    <PageWrapper>
      <Header />
      <TarotIntro />
      <Tarot />
      <div className={classes.offset} >
        <Features />
        <AboutTarot />
        <FaqTarot />
        <FeedbacksTarot />
      </div>
      <Footer />
    </PageWrapper>
  </>
)