import { ImagesMap } from "core/interfaces/images";

import SUSAN_1X from '../../palm-reading/images/susan.webp';
import SUSAN_2X from '../../palm-reading/images/susan@2x.webp';
import SUSAN_3X from '../../palm-reading/images/susan@3x.webp';

import DAVID_1X from '../../palm-reading/images/david.webp';
import DAVID_2X from '../../palm-reading/images/david@2x.webp';
import DAVID_3X from '../../palm-reading/images/david@3x.webp';

import ERIC_1X from '../../palm-reading/images/eric.webp';
import ERIC_2X from '../../palm-reading/images/eric@2x.webp';
import ERIC_3X from '../../palm-reading/images/eric@3x.webp';

import LAUREN_1X from './lauren.webp';
import LAUREN_2X from './lauren@2x.webp';
import LAUREN_3X from './lauren@3x.webp';

import LISA_1X from './lisa.webp';
import LISA_2X from './lisa@2x.webp';
import LISA_3X from './lisa@3x.webp';

import CHRIS_1X from './chris.webp';
import CHRIS_2X from './chris@2x.webp';
import CHRIS_3X from './chris@3x.webp';

export const IMAGES: ImagesMap = {
  LAUREN: {
    src: LAUREN_1X,
    srcSet: `${LAUREN_1X}, ${LAUREN_2X} 2x, ${LAUREN_3X} 3x`,
  },
  LISA: {
    src: LISA_1X,
    srcSet: `${LISA_1X}, ${LISA_2X} 2x, ${LISA_3X} 3x`,
  },
  CHRIS: {
    src: CHRIS_1X,
    srcSet: `${CHRIS_1X}, ${CHRIS_2X} 2x, ${CHRIS_3X} 3x`,
  },
  SUSAN: {
    src: SUSAN_1X,
    srcSet: `${SUSAN_1X}, ${SUSAN_2X} 2x, ${SUSAN_3X} 3x`,
  },
  DAVID: {
    src: DAVID_1X,
    srcSet: `${DAVID_1X}, ${DAVID_2X} 2x, ${DAVID_3X} 3x`,
  },
  ERIC: {
    src: ERIC_1X,
    srcSet: `${ERIC_1X}, ${ERIC_2X} 2x, ${ERIC_3X} 3x`,
  }
};
