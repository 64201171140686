import React from 'react';
import { useSwiper } from 'swiper/react';

import { ReactComponent as Arrow } from './arrow.svg';

import classes from './style.module.scss';

interface Props {
  text: string;
}

export const SliderController: React.FC<Props> = ({ text }) => {
  const swiper = useSwiper();

  return (
    <div className={classes.wrapper}>
      <Arrow className="swiper-button-prev" onClick={() => swiper.slidePrev()}/>
      <span className={classes.text}>{text}</span>
      <Arrow className="swiper-button-next" onClick={() => swiper.slideNext()}/>
    </div>
  )
}