import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Input } from 'core/ui-elements';

import PlaceAutocomplete from './autocomplete';

import classes from './style.module.scss';

const PlacePicker = ({
  value,
  onChange,
  onFocus,
  placeholder,
  className,
  inputClass,
  autoFocus,
}) => {
  const handleInput = useCallback((e) => {
    onChange({ label: e.target.value });
  }, [onChange]);

  const handleSelect = useCallback((o) => {
    onChange(o);
  }, [onChange]);

  return (
    <div className={classNames(classes.wrapper, className)}>
      <Input
        autoFocus={autoFocus}
        className={classNames(classes.input, inputClass)}
        value={value?.label || ''}
        onChange={handleInput}
        onFocus={onFocus}
        placeholder={placeholder}
      />
      <PlaceAutocomplete
        value={value}
        onSelect={handleSelect}
      />
    </div>
  );
}

PlacePicker.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  className: PropTypes.any,
  inputClass: PropTypes.string,
  autoFocus: PropTypes.bool,
};

PlacePicker.defaultProps = {
  className: null,
  inputClass: '',
  autoFocus: false,
};

export default PlacePicker;
