import React, { StrictMode, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Analytics from '@web-solutions/module-analytics';

import { load } from 'src/store/app/actions';
import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../package.json';

import { Page } from './pages/main';

import { DailyHoroscopesPage } from './pages/daily-horoscopes';
import { PalmReadingPage } from './pages/palm-reading';
import { CompatibilityPage } from './pages/compatibility';
import { BirthChartPage } from './pages/birth-chart';
import { TarotPage } from './pages/tarot';

import { FaqPage } from './pages/faq-page';

import configureStore from './store';
import { initLocalization } from './localization';

import 'src/styles/global.scss';
import 'src/styles/index.scss';

const App = () => {
  const { store, persistor } = configureStore();

  const lng = window.location.pathname.split('/')[1];

  Analytics.init(getMagnusToken(), packageInfo.version);

  initLocalization();
  store.dispatch(load());

  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<div />}>
            <BrowserRouter basename={lng}>
              <Routes>
                <Route path='/' element={<Page />} />
                <Route path='/faq' element={<FaqPage />} />
                <Route path='/daily-horoscopes' element={<DailyHoroscopesPage />} />
                <Route path='/palm-reading' element={<PalmReadingPage />} />
                <Route path='/compatibility' element={<CompatibilityPage />} />
                <Route path='/birth-chart' element={<BirthChartPage />} />
                <Route path='/tarot-readings' element={<TarotPage />} />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);