import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { Title, Block } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  title: string;
  children: React.ReactNode;
  className?: string;
}

export const Collapse: React.FC<Props> = ({ title, children, className }) => {
  const [opened, setOpened] = useState(false);
  const blockClassName = classNames(classes.block, className, { [classes.collapsed]: !opened });

  const handleClick = useCallback(() => {
    setOpened(!opened);
  }, [opened, setOpened]);

  return (
    <Block className={blockClassName} onClick={handleClick} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
      <Title level="h3" className={classes.title} itemProp="name">{title}</Title>
      <div className={classes.content} itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">{children}</div>
    </Block>
  );
};