import WAVES_1X from 'astroline-web/src/screens/subscription/sections/compatibility-report/images/result/waves-min.webp'
import WAVES_2X from 'astroline-web/src/screens/subscription/sections/compatibility-report/images/result/waves2x-min.webp'
import WAVES_3X from 'astroline-web/src/screens/subscription/sections/compatibility-report/images/result/waves3x-min.webp'

import { ImagesMap } from 'core/interfaces/images';


const IMAGES: ImagesMap = {
  WAVES: {
    src: WAVES_1X,
    srcSet: `${WAVES_1X}, ${WAVES_2X} 2x, ${WAVES_3X} 3x`,
  }
};

export default IMAGES;