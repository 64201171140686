import React, { Fragment, useState } from 'react';
import classNames from 'classnames'

import { t } from '@web-solutions/module-localization';

import { STREET, POST_C0DE, CITY, HELP_CENTER_LINK } from 'src/constants/general';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { SuccessPopup } from '../components/success-popup';

import ContactModal from '../components/contact-modal';

import classes from './style.module.scss';

type Links = { [key: string]: string };

const tKey = 'sections.footer';

export const Footer: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const { policyLinks } = useRemoteConfig();
  const links: Links = {
    privacy_policy: policyLinks?.privacy,
    terms: policyLinks?.termsOfUse,
    billing_terms: policyLinks?.billingTerms,
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowSuccessPopup(true);
  }

  return (
    <>
      <footer className={classes.footer}>
        <div className={classes.container} itemScope itemType="http://schema.org/Organization">
          <div className={classes.header}>
            <span className={classes.link} onClick={() => setShowModal(true)}>
              {t(`${tKey}.contact_us`)}
            </span>
            <a href={HELP_CENTER_LINK} className={classNames(classes.link, classes.helpCenter)} target="__blank">
              {t(`${tKey}.help_center`)}
            </a>
          </div>
          <div className={classes.address} itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
            <span itemProp="streetAddress">{STREET}, </span>
            <span itemProp="postalCode">{POST_C0DE}, </span>
            <span itemProp="addressLocality">{CITY}</span>
          </div>
        </div>
        <div className={classes.container}>
          {Object.keys(links).map((key, index) => (
            <Fragment key={key}>
              <a href={links[key]} className={classes.link} target="__blank">
                {t(`${tKey}.${key}`)}
              </a>
              {!(index % 2)
                ? <span className={classes.divider} />
                : <br />
              }
            </Fragment>
          ))}
          <a href={policyLinks?.moneyBack} className={classes.link} target="__blank">
            {t(`${tKey}.money_back`)}
          </a>
        </div>
      </footer>
      <ContactModal
        active={showModal}
        onCloseModal={() => setShowModal(false)}
        onCloseModalAfterSubmit={handleCloseModal}
      />
      <SuccessPopup
        active={showSuccessPopup}
        onClosePopup={() => setShowSuccessPopup(false)}
      />
    </>

  );
};