import React from 'react';

import { t } from '@web-solutions/module-localization';

import { BaseFeatures } from '../base';

import { IMAGES } from './images';


const tKey = 'sections.birth_chart_features';

export const Features: React.FC = () => {
  const FEATURES_CONTENT = t(`${tKey}.features`, { returnObjects: true }) as any;

  return (
    <BaseFeatures title={t(`${tKey}.title`)} content={FEATURES_CONTENT} images={IMAGES} />
  )
}