import React from 'react';

import { t } from '@web-solutions/module-localization';

import PageIntro from '../page-intro';

import { IMAGES } from './images';

const tKey = 'sections.birth_chart_intro';

const BirthChartIntro = () => {
  return (
    <PageIntro title={t(`${tKey}.title`)} text={t(`${tKey}.text`)} image={IMAGES.BIRTH_CHART} />
  );
};

export default BirthChartIntro;
