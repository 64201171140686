import React from 'react';
import { Helmet } from 'react-helmet';

import BirthChartIntro from 'src/sections/intro/birth-chart';
import { FaqBirthChart } from 'src/sections/faq/birth-chart';
import { AboutBirthChart } from 'src/sections/about/birth-chart';
import { FeedbacksBirthChart } from 'src/sections/feedbacks/birth-chart';
import { Features } from 'src/sections/features/birth-chart';
import { Header } from 'src/sections/header/';
import { BirthChart } from 'src/sections/birth-chart';
import { Footer } from 'src/sections/footer';

import { PageWrapper } from 'src/components/page-wrapper';

export const BirthChartPage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Astrology Birth Chart — Find the Key to Your Unique Personality 🔑</title>
        <meta property="og:title"
          content="Astrology Birth Chart — Find the Key to Your Unique Personality 🔑" />
        <meta name="description" content="Your astrology birth chart holds the key to understanding your uniqueness. Get a full snapshot of your personality traits and use this knowledge to shape your best life." />
        <meta property="og:description" content="Your astrology birth chart holds the key to understanding your uniqueness. Get a full snapshot of your personality traits and use this knowledge to shape your best life." />
      </Helmet>
      <PageWrapper>
        <Header />
        <BirthChartIntro />
        <BirthChart />
        <Features />
        <AboutBirthChart />
        <FaqBirthChart />
        <FeedbacksBirthChart />
        <Footer />
      </PageWrapper>
    </>
  )
}