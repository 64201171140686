import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Button, Modal } from 'core/ui-elements';

import { ReactComponent as Icon } from './done.svg';

import classes from './style.module.scss';

const tKey = 'success_popup';

interface Props {
  active: boolean;
  onClosePopup: () => void;
}

export const SuccessPopup: React.FC<Props> = ({ active, onClosePopup }) => {
  return (
    <Modal
      className={classes.container}
      isOpen={active}
      overlayClassName={classes.overlay}
    >
      <div className={classes.popup}>
        <div className={classes.texts}>
          <div className={classes.top}>
            <div className={classes.icon}>
              <Icon />
            </div>
            <p className={classes.title}>{t(`${tKey}.title`)}</p>
          </div>
          <p className={classes.text}>{t(`${tKey}.text`)}</p>
        </div>
        <Button
          title={t(`${tKey}.button`)}
          className={classes.button}
          onClick={onClosePopup}
        />
      </div>
    </Modal>
  )
}
