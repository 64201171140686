import Localization from '@web-solutions/module-localization';

import { APP_NAME, SUPPORT_EMAIL } from 'src/constants/general';

const resources = {
  en: {
    translation: require('./locales/en.json'),
  },
};

export function initLocalization() {
  Localization.init(resources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL }, { lng: window.location.pathname.split('/')[1] });
}
