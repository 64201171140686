import React from 'react';
import { Helmet } from 'react-helmet';

import PalmReadingIntro from 'src/sections/intro/palm-reading';
import { PalmLines } from 'src/sections/palm-lines';
import { FaqPalmReading } from 'src/sections/faq/palm-reading';
import { AboutPalmReading } from 'src/sections/about/palm-reading';
import { FeedbacksPalmReading } from 'src/sections/feedbacks/palm-reading';
import { Features } from 'src/sections/features/palm-reading';
import { Header } from 'src/sections/header/';
import { Footer } from 'src/sections/footer';

import { PageWrapper } from 'src/components/page-wrapper';

export const PalmReadingPage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Palm Reading Online - Try Astroline’s Smart Palm Reading Scanner 🤲</title>
        <meta property="og:title"
          content="Palm Reading Online - Try Astroline’s Smart Palm Reading Scanner 🤲" />
        <meta name="description" content="Uncover your character and destiny with our AI-driven palm readings online. See what your palm lines reveal about your love life, health, career, and more!" />
        <meta property="og:description" content="Uncover your character and destiny with our AI-driven palm readings online. See what your palm lines reveal about your love life, health, career, and more!" />
      </Helmet>
      <PageWrapper>
        <Header />
        <PalmReadingIntro />
        <PalmLines />
        <Features />
        <AboutPalmReading />
        <FaqPalmReading />
        <FeedbacksPalmReading />
        <Footer />
      </PageWrapper>
    </>
  )
}