import { createAction } from 'redux-actions';

import { init as initRemoteConfig } from 'core/store/remote-config/actions';

import { INITIAL_CONFIG, } from 'src/constants/remote-config';

import { getMagnusToken } from './utils';
import { SET_LOADED, SET_PENDING } from './types';

export const setLoaded = createAction(SET_LOADED);
export const setPending = createAction(SET_PENDING);

export const load = () => async (dispatch) => {
  try {
    await dispatch(initRemoteConfig(getMagnusToken(), INITIAL_CONFIG));
  } catch (error) {
    console.warn('[ERROR INIT APP]', error);
  } finally {
    dispatch(setLoaded(true));
  }
};
