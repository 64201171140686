import React from 'react';

import { t } from '@web-solutions/module-localization';

import PageIntro from '../page-intro';

import { IMAGES } from './images';

const tKey = 'sections.horoscopes_intro';

const HoroscopesIntro = () => {
  return (
    <PageIntro title={t(`${tKey}.title`)} text={t(`${tKey}.text`)} image={IMAGES.HOROSCOPES} />
  );
};

export default HoroscopesIntro;
