import { ImagesMap } from "core/interfaces/images";

import CHART_1X from './chart.webp';
import CHART_2X from './chart@2x.webp';
import CHART_3X from './chart@3x.webp';

import COMPATIBILITY_1X from './compatibility.webp';
import COMPATIBILITY_2X from './compatibility@2x.webp';
import COMPATIBILITY_3X from './compatibility@3x.webp';

import HOROSCOPES_1X from './horoscopes.webp';
import HOROSCOPES_2X from './horoscopes@2x.webp';
import HOROSCOPES_3X from './horoscopes@3x.webp';

import PALM_1X from './palm.webp';
import PALM_2X from './palm@2x.webp';
import PALM_3X from './palm@3x.webp';

import TAROT_1X from './tarot.webp';
import TAROT_2X from './tarot@2x.webp';
import TAROT_3X from './tarot@3x.webp';


export const IMAGES: ImagesMap = {
  CHART: {
    src: CHART_1X,
    srcSet: `${CHART_1X}, ${CHART_2X} 2x, ${CHART_3X} 3x`
  },
  COMPATIBILITY: {
    src: COMPATIBILITY_1X,
    srcSet: `${COMPATIBILITY_1X}, ${COMPATIBILITY_2X} 2x, ${COMPATIBILITY_3X} 3x`
  },
  HOROSCOPES: {
    src: HOROSCOPES_1X,
    srcSet: `${HOROSCOPES_1X}, ${HOROSCOPES_2X} 2x, ${HOROSCOPES_3X} 3x`
  },
  PALM: {
    src: PALM_1X,
    srcSet: `${PALM_1X}, ${PALM_2X} 2x, ${PALM_3X} 3x`
  },
  TAROT: {
    src: TAROT_1X,
    srcSet: `${TAROT_1X}, ${TAROT_2X} 2x, ${TAROT_3X} 3x`
  },
}