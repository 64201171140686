import _findKey from 'lodash/findKey';

import dayjs from 'dayjs';

import { ZODIACS, zodiacsStarts } from 'astroline-web/src/constants/zodiac';

import { ANIMALS, PLANETS, ELEMENTS, MATCHES } from 'astroline-web/src/screens/subscription/sections/personalised_plan/data';

function getZodiac(month: number, day: number) {
  if (month && day) {
    const dateCode = month * 100 + day;
    return _findKey(zodiacsStarts, (v) => dateCode <= parseInt(v)) || ZODIACS.CAPRICORN;
  } else {
    return null;
  }
}

export const getZodiacName = (date: string = '') => {
  try {
    const [, month, day] = date.split('-').map(Number);
    return getZodiac(month, day);
  }
  catch (ex) {
    //@ts-ignore
    return getZodiac();
  }
};

export const getAnimal = (year: string) => {
  let animal = ANIMALS[0]?.animal;
  ANIMALS.forEach((item) => {
    const numYear = parseFloat(year);
    if (item.years.includes(numYear)) {
      animal = item.animal;
    }
  });
  return animal;
};

export const getDate = (dateCode: string) => {
  let month: string;
  let day: number;
  if (dateCode.length === 3) {
    month = dateCode[0];
    day = parseInt(dateCode.substring(1))
  } else {
    month = dateCode.substr(0, 2);
    day = parseInt(dateCode.substring(2))
  }
  return {
    month,
    day
  }
}

export const getNextDayDate = (date: { month: string, day: number }) => {  // made it because in this case we don't have contact of months
  return {
    month: date.month,
    day: date.day + 1
  }
}

export const getZodiacPeriod = (name: string) => {
  const zodiacs = Object.values(zodiacsStarts);
  const startDate = zodiacs[zodiacs.indexOf(zodiacsStarts[name]) - 1] || zodiacs[zodiacs.length - 1];
  const endDate = zodiacsStarts[name];
  const dates = {
    start: getNextDayDate(getDate(startDate)),
    end: getDate(endDate)
  }
  return dates;
};

export const getPeriod = (key: string) => {
  const preparePeriod = getZodiacPeriod(key);

  const startMonth = parseInt(preparePeriod.start.month);
  const endMonth = parseInt(preparePeriod.end.month);

  const startDate = dayjs().month(startMonth - 1).date(preparePeriod.start.day);
  const endDate = dayjs().month(endMonth - 1).date(preparePeriod.end.day);

  return `${preparePeriod.start.day} ${startDate.format('MMM')} - ${preparePeriod.end.day} ${endDate.format('MMM')}`;
}

export const getPlanet = (zodiac: string) => PLANETS[zodiac] || null;

export const getElement = (zodiac: string) => ELEMENTS[zodiac] || null;

export const getBestMatch = (zodiac: string) => MATCHES[zodiac] || null;