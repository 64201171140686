import React from 'react';

import { ImageSource } from 'core/interfaces/images';

import { Title, Section } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  image: ImageSource,
  title: string,
  text: string,
  bottomText?: string, 
}

const PageIntro: React.FC<Props> = ({ image, title, text, bottomText }) => {
  return (
    <Section className={classes.intro}>
      <Title level='h1' className={classes.title}>
        {title}
      </Title>
      <main>
        <img src={image.src} srcSet={image.srcSet} alt='pic' />
        <div className={classes.content}>
          <p className={classes.text}>{text}</p>
          <p className={classes.text}>{bottomText}</p>
        </div>
      </main>
    </Section>
  );
};

export default PageIntro;
