import React from 'react';

import { t } from '@web-solutions/module-localization';

import { About } from '../base';

const tKey = 'sections.about.birth_chart';

export const AboutBirthChart: React.FC = () => {
  const content = t(`${tKey}.content`, { returnObjects: true }) as any;

  return (
    <About content={content} h2IndexesTopPart={[5,10]} title={t(`${tKey}.title`)} />
  )
}