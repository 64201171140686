import React from 'react';
import classNames from 'classnames';

import { ImageSource } from 'core/interfaces/images';
import { Block } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  date: string,
  zodiacName: string,
  image: ImageSource,
  onZodiacClick: (id: any) => void;
  active: boolean;
}

export const SliderTile: React.FC<Props> = ({ date, zodiacName, image, onZodiacClick, active }) => {
  return (
    <Block className={classNames(classes.tile, {[classes.active]: active})} onClick={onZodiacClick}>
       <img src={image.src} srcSet={image.srcSet} alt="zodiac sign" className={classes.image}/>
      <span className={classes.zodiac_name}>{zodiacName}</span>
      <span className={classes.date}>{date}</span>
    </Block>
  )
}