import { ImagesMap } from "core/interfaces/images"

import WEEKLY_1X from './weekly.webp'
import WEEKLY_2X from './weekly@2x.webp'
import WEEKLY_3X from './weekly@3x.webp'

import MONTHLY_1X from './monthly.webp'
import MONTHLY_2X from './monthly@2x.webp'
import MONTHLY_3X from './monthly@3x.webp'

import YEARLY_1X from './yearly.webp'
import YEARLY_2X from './yearly@2x.webp'
import YEARLY_3X from './yearly@3x.webp'

import PREMIUM_1X from './premium.webp'
import PREMIUM_2X from './premium@2x.webp'
import PREMIUM_3X from './premium@3x.webp'

export const IMAGES: ImagesMap = {
  WEEKLY: {
    src: WEEKLY_1X,
    srcSet: `${WEEKLY_1X}, ${WEEKLY_2X} 2x, ${WEEKLY_3X} 3x`
  },
  MONTHLY: {
    src: MONTHLY_1X,
    srcSet: `${MONTHLY_1X}, ${MONTHLY_2X} 2x, ${MONTHLY_3X} 3x`
  },
  YEARLY: {
    src: YEARLY_1X,
    srcSet: `${YEARLY_1X}, ${YEARLY_2X} 2x, ${YEARLY_3X} 3x`
  },
  PREMIUM: {
    src: PREMIUM_1X,
    srcSet: `${PREMIUM_1X}, ${PREMIUM_2X} 2x, ${PREMIUM_3X} 3x`
  },
}