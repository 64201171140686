import React, { useRef } from 'react';
import classNames from 'classnames';

import { Theme } from 'core/constants/theme';

import { ReactComponent as IconBurger } from './icons/burger.svg';
import { ReactComponent as IconClose } from './icons/close.svg';

import classes from './style.module.scss';

interface Props {
  children: React.ReactNode,
  onVisibilityChange: () => void,
  visible: boolean,
  theme?: Theme,
}

export const SideBar: React.FC<Props> = ({
  children,
  onVisibilityChange,
  visible,
  theme = Theme.light
}) => {
  const r = useRef(null);

  const handleBackDropClick = (e: React.SyntheticEvent) => {
    if (e.target === r.current) {
      onVisibilityChange();
    }
  }

  const classWrapper = classNames(classes.wrapper, {
    [classes.show]: visible,
    [classes.dark]: theme === Theme.dark,
  })

  return (
    <div className={classWrapper} ref={r} onClick={handleBackDropClick}>
      <div className={classes.icon}>
        <IconBurger className={classes.open} onClick={onVisibilityChange} />
        <div className={classes.menu}>
          <IconClose className={classes.close} onClick={onVisibilityChange} />
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  )
};
