import { useState, useEffect, } from 'react';
import queryString from 'query-string';

import DeviceProps from '@magnus/react-native-device-props'

import {
  EMPTY_VALUE,
  type IDeviceInfo,
} from '@wowmaking/helpdesk';

import { APP_NAME } from 'src/constants/general'

export const useGetDeviceInfoData = () => {
  const { utm_source, idfm } = queryString.parse(window.location.search);

  const initialDeviceInfoData: IDeviceInfo = {
    app_name: APP_NAME ?? EMPTY_VALUE,
    paid: EMPTY_VALUE,
    tracker: utm_source as string ?? EMPTY_VALUE,
    idfm: idfm as string ?? EMPTY_VALUE
  }

  const [deviceInfoData, setDeviceInfoData] = useState(() => initialDeviceInfoData)

  useEffect(() => {
    Promise.all([
      DeviceProps.fetch(),
      DeviceProps.getAppVersion()
    ])
      //@ts-ignore
      .then(([{ os_name, os_version, device_model }, version]) => {
        setDeviceInfoData((prev) => ({
          ...prev,
          version,
          platform: os_name,
          platform_version: os_version,
          device_name: device_model
        }))
      })
      .catch((error) => console.log(error))
  }, [])

  return deviceInfoData
}
