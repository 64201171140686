import { ImagesMap } from "core/interfaces/images";

import LAURA_1X from '../../base/images/laura.webp';
import LAURA_2X from '../../base/images/laura@2x.webp';
import LAURA_3X from '../../base/images/laura@3x.webp';

import MARK_1X from '../../base/images/mark.webp';
import MARK_2X from '../../base/images/mark@2x.webp';
import MARK_3X from '../../base/images/mark@3x.webp';

import KAREN_1X from '../../base/images/karen.webp';
import KAREN_2X from '../../base/images/karen@2x.webp';
import KAREN_3X from '../../base/images/karen@3x.webp';

import SUSAN_1X from './susan.webp';
import SUSAN_2X from './susan@2x.webp';
import SUSAN_3X from './susan@3x.webp';

import DAVID_1X from './david.webp';
import DAVID_2X from './david@2x.webp';
import DAVID_3X from './david@3x.webp';

import ERIC_1X from './eric.webp';
import ERIC_2X from './eric@2x.webp';
import ERIC_3X from './eric@3x.webp';

export const IMAGES: ImagesMap = {
  SUSAN: {
    src: SUSAN_1X,
    srcSet: `${SUSAN_1X}, ${SUSAN_2X} 2x, ${SUSAN_3X} 3x`,
  },
  DAVID: {
    src: DAVID_1X,
    srcSet: `${DAVID_1X}, ${DAVID_2X} 2x, ${DAVID_3X} 3x`,
  },
  ERIC: {
    src: ERIC_1X,
    srcSet: `${ERIC_1X}, ${ERIC_2X} 2x, ${ERIC_3X} 3x`,
  },


  LAURA: {
    src: LAURA_1X,
    srcSet: `${LAURA_1X}, ${LAURA_2X} 2x, ${LAURA_3X} 3x`,
  },
  MARK: {
    src: MARK_1X,
    srcSet: `${MARK_1X}, ${MARK_2X} 2x, ${MARK_3X} 3x`,
  },
  KAREN: {
    src: KAREN_1X,
    srcSet: `${KAREN_1X}, ${KAREN_2X} 2x, ${KAREN_3X} 3x`,
  }
};
