import _map from 'lodash/map';
import React from 'react';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { ImagesMap } from 'core/interfaces/images';

import { EVENT_ACTION } from 'core/constants/general';

import { ROUTES } from 'src/constants/routes';

import { SubButton } from 'src/sections/components/sub-button';

import Chapter from '../components/chapter';

import classes from './style.module.scss';

interface Props {
  title: string,
  content: any,
  images: ImagesMap,
  isLink?: boolean;
}

const tKey = 'sections.explore_features';

export const BaseFeatures: React.FC<Props> = ({ title, content, images, isLink }) => {
  const handleClick = () => {
    if (isLink) {
      Analytics.trackEvent(`terms_menu_faq`, EVENT_ACTION.CLICK);
    }
  }
  return (
    <Section className={classes.features}>
      <Title level='h2' className={classes.title}>
        {title}
      </Title>
      <div className={classes.items}>
        {
          _map(content, (v, k) => (
            <Chapter
              title={v.title}
              text={v.text}
              image={images[k]}
              key={k}
              path={ROUTES[k]}
              onChapterClick={handleClick}
              link={isLink ? <p className={classes.link}>{t(`${tKey}.more`)}</p> : null}
            />
          )
          )
        }
      </div>
      <SubButton text={t(`sections.button_text`)} className={classes.sub_button} />
    </Section>
  )
}