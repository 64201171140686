import { ImagesMap } from "core/interfaces/images"

import PICK_1X from './pic.webp'
import PICK_2X from './pic@2x.webp'
import PICK_3X from './pic@3x.webp'

export const IMAGES: ImagesMap = {
  PIC: {
    src: PICK_1X,
    srcSet: `${PICK_1X}, ${PICK_2X} 2x, ${PICK_3X} 3x`
  }
}