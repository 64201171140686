import React from 'react';

import { ImageSource } from 'core/interfaces/images';

import { ReactComponent as Arrow } from './arrow.svg';

import classes from './style.module.scss';

interface Props {
  image: ImageSource,
  title: string,
  onTileClick: () => void,
}

const Tile: React.FC<Props> = ({ image, title, onTileClick }) => {
  return (
    <li className={classes.tile} onClick={onTileClick}>
      <Arrow className={classes.arrow} />
      <img src={image.src} srcSet={image.srcSet} alt='pic' />
      <span className={classes.title}>{title}</span>
    </li>
  )
};

export default Tile;
