import { ImagesMap } from "core/interfaces/images"

import PALM_1X from './palm.webp'
import PALM_2X from './palm@2x.webp'
import PALM_3X from './palm@3x.webp'

export const IMAGES: ImagesMap = {
  PALM: {
    src: PALM_1X,
    srcSet: `${PALM_1X}, ${PALM_2X} 2x, ${PALM_3X} 3x`
  },
}