import _map from 'lodash/map';
import React, { useState } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { ImagesMap } from "core/interfaces/images";

import { SubButton } from '../../components/sub-button';

import { ShowMoreLessButton } from '../../components/show-more-less-button';

import SlideWithStars from '../components/slide-with-stars';

import { IMAGES as defaulfImages } from './images';

import classes from './style.module.scss';

const tKey = 'sections.feedbacks';

export type FEEDBACKS = {
  [key: string]: {
    title: string;
    text: string;
  }
}

interface Props {
  feedbacks?: FEEDBACKS;
  images?: ImagesMap;
}

export const BaseFeedbacks: React.FC<Props> = ({ feedbacks, images }) => {
  const [showMore, setShowMore] = useState(false);
  const defaultfeedbacks = t(`${tKey}.sliders`, { returnObjects: true }) as any as FEEDBACKS;

  return (
    <Section className={classes.list}>
      <Title level="h2" className={classes.title}>{t(`${tKey}.title`)}</Title>
      <div className={classNames(classes.content, { [classes.hidden]: !showMore })}>
        {
          _map(feedbacks ?? defaultfeedbacks, (v, k) => (
            <SlideWithStars
              text={v.text}
              title={v.title}
              image={images ? images[k] : defaulfImages[k]}
              key={k}
              className={classes.feedback}
            />
          ))
        }
      </div>
      <ShowMoreLessButton onButtonClick={() => setShowMore(!showMore)} showMore={showMore} />
      <SubButton text={t(`sections.button_text`)} className={classes.sub_button} />
    </Section>
  )
};
