import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss';

interface Props {
  text: string;
  onButtonClick: () => void;
  active: boolean;
}

export const GetResultButton: React.FC<Props> = ({ text, onButtonClick, active }) => (
  <button
    disabled={active ? false : true}
    className={classNames(classes.button, { [classes.active]: active })}
    onClick={onButtonClick}>
    {text}
  </button>
);

