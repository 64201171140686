import _map from 'lodash/map';
import React from 'react';
import { useNavigate } from "react-router-dom";

import { t } from '@web-solutions/module-localization';

import { Title } from 'core/ui-elements';

import { ReactComponent as ArrowIcon } from 'src/shared/icons/arrow.svg';

import { Collapse } from 'src/sections/components/collapse';

import classes from './style.module.scss';

const tKey = 'sections.faq';

export const FaqPage: React.FC = () => {
  const questions = t(`${tKey}.main.questions`, { returnObjects: true }) as any;

  const navigate = useNavigate();  

  const handleToBack = () => {
    navigate(-1);
  }

  return (
    <div className={classes.faq_page}>
      <header>
        <button className={classes.arrow_button} onClick={handleToBack}>
          <ArrowIcon className={classes.arrow} />
        </button>
        <Title className={classes.title}>{t(`${tKey}.title`)}</Title>
      </header>
      {_map(questions, ({ title, text }) => (
        <Collapse key={title} title={title}>
          <div className={classes.text}>{text}</div>
        </Collapse>
      ))}
    </div>
  )
}