import STARS from './stars.webp';
import STARS2X from './stars@2x.webp';
import STARS3X from './stars@3x.webp';

export const IMAGES = {
  STARS: {
    src: STARS,
    srcSet: `${STARS}, ${STARS2X} 2x, ${STARS3X} 3x`,
  },
};
