import React, { FC } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { ZODIAC_ICONS } from '../../constants';
import { IMAGES } from '../../images';

import classes from './style.module.scss';

interface Props {
  zodiacName: string;
  elementCode: string;
  isUserSign: boolean;
}

const ZodiacSign: FC<Props> = ({ zodiacName, elementCode, isUserSign }) => {
  const iconSrc = ZODIAC_ICONS?.[zodiacName];
  return (
    <div className={classes.container}>
      <div className={classNames(classes.result, classes[`result_${isUserSign ? 'left' : 'right'}`])}>
        <img src={iconSrc} className={classes.result__img} alt="zodiac"/>
        <div className={classes.result__title}>{`${t(`zodiac.${zodiacName}`)}`}</div>
      </div>
      <div className={classes.element}>
        <img
          className={classes.element__img}
          src={IMAGES?.[elementCode]?.src}
          srcSet={IMAGES?.[elementCode]?.srcSet}
          alt="element"
        />
        <div className={classes.element__title}>
          {`${t(`elements.${elementCode}`)}`}
        </div>
      </div>
    </div>
  )
};

export default ZodiacSign;