import React from 'react';

import { Block, Title } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  title: string;
  text: string;
  number: number;
}

export const ChainLink: React.FC<Props> = ({ title, text, number }) => {
  return (
    <Block className={classes.chain_link}>
      <div className={classes.top_part}>
        <div className={classes.border}>
          <div className={classes.number}>{number}</div>
        </div>
        <Title level='h3' className={classes.title}>{title}</Title>
      </div>
      <p className={classes.text}>{text}</p>
    </Block>
  )
}