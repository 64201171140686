import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import { Section, Title } from 'core/ui-elements';

import { SubButton } from 'src/sections/components/sub-button';

import { OverlayTextBlock } from '../components/overlay-text-block';

import classes from './style.module.scss';

const tKey = 'sections.tarot';

const iframe_URL = process.env.REACT_APP_IFRAME_TAROT;

export const Tarot: React.FC = () => {
  const [showOverlayTextBlock, setShowOverlayTextBlock] = useState(false);
  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleMessage = (e: any) => {
    if (typeof (e.data) === 'string') {
      setShowOverlayTextBlock(JSON.parse(e.data).data.isResultPage)
    }
  }

  return (
    <Section className={classes.wrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      <iframe src={iframe_URL} title="tarot" />
      <div className={classNames(classes.overlay, { [classes.show]: showOverlayTextBlock })}>
        <OverlayTextBlock text={t(`${tKey}.overlayText`)} className={classes.other_position} />
        <SubButton text={t('sections.read_more_btn')} className={classes.sub_button} />
      </div>
    </Section>
  )
}