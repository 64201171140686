import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Faq } from '../base';

const tKey = 'sections.faq.palm_reading';

export const FaqPalmReading: React.FC = () => {
  const questions = t(`${tKey}.questions`, { returnObjects: true }) as any;

  return (
    <Faq questions={questions} />
  )
}