import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { SubButton } from '../components/sub-button';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'sections.palm_lines';
const COLORS = ['#F27067', '#F9D05D', '#14D5C2', '#C583FA']

export const PalmLines: React.FC = () => {
  const lines = t(`${tKey}.lines`, { returnObjects: true }) as any as { highlight: string, text: string }[];
  return (
    <Section className={classes.wrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      <img src={IMAGES.PALM.src} srcSet={IMAGES.PALM.srcSet} alt='palm' className={classes.image} />
      <div className={classes.lines}>
        {lines.map((line, index) => (
          <div key={index} className={classes.line}>
            <div className={classes.dot} />
            <p className={classes.text}><b style={{ color: COLORS[index] }}>{line.highlight}</b>{line.text}</p>
          </div>
        ))}
      </div>
      <SubButton text={t(`sections.button_text`)} className={classes.sub_button} />
    </Section>
  )
}