import React, { useState } from 'react';
import queryString from 'query-string';

import { t } from '@web-solutions/module-localization';

import { Section, Title } from 'core/ui-elements';

import { SubButton } from 'src/sections/components/sub-button';

import { GetResultButton } from '../components/get-result-button';
import { OverlayTextBlock } from '../components/overlay-text-block';

import { Form } from './components/form';

import classes from './style.module.scss';

export type InitialData = {
  place: { name: string, lat: number, lon: number } | null,
  month: number | null,
  day: number | null,
  year: number | null,
  hours: number | null,
  minutes: number | null,
}

const initialData: InitialData = {
  place: null,
  month: null,
  day: null,
  year: null,
  hours: null,
  minutes: null,
}

const requiredFields = ['place', 'month', 'day', 'year'] as const;

export type Place = {
  address: {
    [key: string]: string
  }
  label: string;
  lat: number;
  lon: number
}

const tKey = 'sections.birth_chart';
const defaultTime = '12:00';
const iframe_URL = process.env.REACT_APP_IFRAME_BIRTH_CHART;

export const BirthChart: React.FC = () => {
  const [showResult, setShowResult] = useState(false);
  const [data, setData] = useState(initialData);
  const [place, setPlace] = useState<Place | null>(null);
  const [validation, setValidation] = useState({
    place: true,
    month: true,
    day: true,
    year: true,
    hours: true,
    minutes: true
  });

  const validateField = (name: string, value: number) => {
    switch (name) {
      case 'day':
        return value >= 1 && value <= 31
      case 'month':
        return value >= 1 && value <= 12
      case 'hours':
        return (value >= 0 && value <= 23) || null
      case 'minutes':
        return (value >= 0 && value <= 59) || null
      case 'year':
        return value >= 1900 && value <= 2050
    }
  };

  const updateValidationField = (name: string, value: number) => {
    const isValid = validateField(name, value);
    setValidation(prevValidation => ({
      ...prevValidation,
      [name]: isValid
    }));
  }

  const handlePlaceChange = (value: Place) => {
    setPlace(value);
    setData({ ...data, place: { name: value.label, lat: +value.lat, lon: +value.lon } });
    setValidation(prevValidation => ({
      ...prevValidation,
      place: !isNaN(value?.lat!)
    }));
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    switch (name) {
      case 'day':
      case 'month':
      case 'hours':
      case 'minutes':
        if (/^\d{0,2}$/.test(value)) {
          updateValidationField(name, value);
          setData({ ...data, [name]: value });
        }
        break;
      case 'year':
        if (/^\d{0,4}$/.test(value)) {
          updateValidationField(name, value);
          setData({ ...data, [name]: value });
        }
        break;
      default:
        setData({ ...data, [name]: value });
        break;
    }
  }

  const birthPlace = JSON.stringify(data.place);
  const birthDate = `${data.year}-${data.month?.toString().padStart(2, "0")}-${data.day?.toString().padStart(2, "0")}`;
  const birthTime = (data.hours && data.minutes) ? `${data.hours.toString().padStart(2, "0")}:${data.minutes.toString().padStart(2, "0")}` : `${defaultTime}`;
  const queryParams = {
    birthPlace,
    birthDate,
    birthTime
  };
  const iframeSrc = `${iframe_URL}?${queryString.stringify(queryParams)}`;

  return (
    <Section className={classes.wrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      {showResult ?
        <div className={classes.overlay}>
          <iframe src={iframeSrc} title="birth-chart report"></iframe>
          <OverlayTextBlock text={t(`${tKey}.overlayText`)} className={classes.other_position} />
        </div>
        :
        <Form
          data={data}
          place={place}
          onPlaceChange={handlePlaceChange}
          onInputChange={handleChange}
          validation={validation}
        />
      }
      {showResult ?
        <SubButton text={t('sections.read_more_btn')} className={classes.sub_button} />
        :
        <GetResultButton
          text={t('sections.get_result_btn')}
          onButtonClick={() => setShowResult(true)}
          active={Object.values(validation).every(value => value === true) && requiredFields.every(field => data[field] !== null)}
        />
      }
    </Section>
  )
}