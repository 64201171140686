import _map from 'lodash/map';
import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Section, Title } from 'core/ui-elements';

import { SubButton } from '../../components/sub-button';

import { Collapse } from '../../components/collapse';

import classes from './style.module.scss';

interface Props {
  questions: any;
}

export const Faq: React.FC<Props> = ({ questions }) => (
  <Section className={classes.faq}>
    <Title level='h2' className={classes.title}>{t('sections.faq.title')}</Title>
    {_map(questions, ({ title, text }) => (
      <Collapse key={title} title={title}>
        <div className={classes.text} itemProp="text">{text}</div>
      </Collapse>
    ))}
    <SubButton text={t(`sections.button_text`)} className={classes.sub_button} />
  </Section>
);
