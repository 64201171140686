import { ImagesMap } from 'core/interfaces/images';

import { ELEMENT } from '../constants';

import WAVES from './result/waves-min.webp';
import WAVES2X from './result/waves2x-min.webp';
import WAVES3X from './result/waves3x-min.webp';
import LEFT_BG from './result/bg-green-min.webp';
import LEFT_BG2X from './result/bg-green2x-min.webp';
import LEFT_BG3X from './result/bg-green3x-min.webp';
import RIGHT_BG from './result/bg-purple-min.webp';
import RIGHT_BG2X from './result/bg-purple2x-min.webp';
import RIGHT_BG3X from './result/bg-purple3x-min.webp';

import AIR from './elements/air.webp';
import AIR2X from './elements/air@2x.webp';
import AIR3X from './elements/air@3x.webp';
import EARTH from './elements/earth.webp';
import EARTH2X from './elements/earth@2x.webp';
import EARTH3X from './elements/earth@3x.webp';
import FIRE from './elements/fire.webp';
import FIRE2X from './elements/fire@2x.webp';
import FIRE3X from './elements/fire@3x.webp';
import WATER from './elements/water.webp';
import WATER2X from './elements/water@2x.webp';
import WATER3X from './elements/water@3x.webp';

export const IMAGES: ImagesMap = {
  [ELEMENT.AIR]: {
    src: AIR,
    srcSet: `${AIR}, ${AIR2X} 2x, ${AIR3X} 3x`,
  },
  [ELEMENT.EARTH]: {
    src: EARTH,
    srcSet: `${EARTH}, ${EARTH2X} 2x, ${EARTH3X} 3x`,
  },
  [ELEMENT.FIRE]: {
    src: FIRE,
    srcSet: `${FIRE}, ${FIRE2X} 2x, ${FIRE3X} 3x`,
  },
  [ELEMENT.WATER]: {
    src: WATER,
    srcSet: `${WATER}, ${WATER2X} 2x, ${WATER3X} 3x`,
  },
  WAVES: {
    src: WAVES,
    srcSet: `${WAVES}, ${WAVES2X} 2x, ${WAVES3X} 3x`,
  },
  LEFT_BG: {
    src: LEFT_BG,
    srcSet: `${LEFT_BG}, ${LEFT_BG2X} 2x, ${LEFT_BG3X} 3x`,
  },
  RIGHT_BG: {
    src: RIGHT_BG,
    srcSet: `${RIGHT_BG}, ${RIGHT_BG2X} 2x, ${RIGHT_BG3X} 3x`,
  },
};

