import React from 'react';
import classNames from 'classnames';

import { ImageSource } from 'core/interfaces/images';
import { Block } from 'core/ui-elements';

import classes from './style.module.scss';

import { IMAGES } from './images';

interface Props {
  title: string;
  text: string;
  image: ImageSource;
  className?: string;
}

const SlideWithStars: React.FC<Props> = ({ title, text, image, className }) => {
  return (
    <Block className={classNames(classes.wrapper, className)} >
      <div className={classes.header}>
        <img
          className={classes.image}
          src={image.src}
          srcSet={image.srcSet}
          alt={title}
        />
        <div className={classes.stars}>
          <p className={classes.title}>{title}</p>
          <img
            className={classes.star}
            src={IMAGES.STARS.src}
            srcSet={IMAGES.STARS.srcSet}
            alt={title}
          />
        </div>
      </div>
      <p className={classes.text}>{text}</p>
    </Block>
  );
};

export default SlideWithStars;
