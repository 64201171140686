import { LString } from 'core/constants/remote-config';

type POLICY_LINKS = {
  termsOfUse: string,
  privacy: string,
  moneyBack: string,
  billingTerms: string,
};

export interface RemoteConfig {
  introSection: {
    title: LString,
    subtitle: LString,
  }
  policyLinks: POLICY_LINKS,
  feedbacks: { title: string, text: string }[],
  showSupportWidget: boolean,
}

export const INITIAL_CONFIG: RemoteConfig = {
  introSection: {
    title: { en: '' },
    subtitle: { en: '' },
  },
  policyLinks: {
    termsOfUse: "",
    privacy: "",
    moneyBack: "",
    billingTerms: ""
  },
  feedbacks: [],
  showSupportWidget: true
};
