import _map from 'lodash/map';
import React, { useEffect, useState } from 'react';

import { getPeriod } from 'astroline-web/src/utils/zodiacConverter';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import instance from 'core/utils/network';

import Tile from './components/tile';

import IMAGES from './images';

import classes from './style.module.scss';

const tKey = 'sections.horoscope_today';

const time_zone = -(new Date().getTimezoneOffset() / 60);

const url = process.env.REACT_APP_SUB_URL!;

interface HoroscopeData {
  sign: string;
  horoscope: {
    text: string;
  };
}

const HoroscopeToday: React.FC = () => {
  const [horoscopes, setHoroscopes] = useState<{ [key: string]: string } | null>(null);
  const zodiacs = t('zodiac', { returnObjects: true }) as any as { [key: string]: string };

  useEffect(() => {
    async function getHoroscopes() {
      try {
        const data = await instance.get(`/horoscope/all?utc_offset=${time_zone}`);
        const result: { [key: string]: string } = {};

        for (const [key] of Object.entries(zodiacs)) {
          const lowercaseKey = key.toLowerCase();
          //@ts-ignore
          const matchingObject = data?.find((obj: HoroscopeData) => obj.sign.toLowerCase() === lowercaseKey);
          if (matchingObject) {
            result[key] = matchingObject.horoscope.text.replace(/<\/?p>/g, "");
          }
        }
        setHoroscopes(result);
      } catch (error) {
        console.error(error);
      }
    }
    getHoroscopes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <Section className={classes.wrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      <div className={classes.tiles} data-url={url}>
        {_map(zodiacs, (v, k) => (
          <Tile
            key={k}
            image={IMAGES[k]}
            zodiacName={t(`zodiac.${k}`)}
            date={getPeriod(k)}
            text={horoscopes ? horoscopes[k] : ''}
            onTileClick={handleClick}
          />
        )
        )}
      </div>
    </Section>
  )
};

export default HoroscopeToday;
