import _map from 'lodash/map';
import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { SubButton } from '../components/sub-button';

import { ChainLink } from './components/chain-link';

import classes from './style.module.scss';


const tKey = 'sections.how_it_works';

export const HowItWorks: React.FC = () => {
  const chain_data = t(`${tKey}.chain`, { returnObjects: true }) as any;

  return (
    <Section className={classes.container}>
      <Title level='h2' className={classes.title}>{t(`${tKey}.title`)}</Title>
      {_map(chain_data, (v, k) => (
        <ChainLink
          title={v.title}
          text={v.text}
          number={Object.keys(chain_data).indexOf(k) + 1}
          key={k}
        />
      ))}
      <SubButton text={t(`sections.button_text`)} className={classes.sub_button}/>
    </Section>
  )
}