import { ImagesMap } from "core/interfaces/images";

import ALGORITM_1X from './algorithm.webp';
import ALGORITM_2X from './algorithm@2x.webp';
import ALGORITM_3X from './algorithm@3x.webp';

import INSIGHTS_1X from './insights.webp';
import INSIGHTS_2X from './insights@2x.webp';
import INSIGHTS_3X from './insights@3x.webp';

import READINGS_1X from './readings.webp';
import READINGS_2X from './readings@2x.webp';
import READINGS_3X from './readings@3x.webp';

import SUGGESTIONS_1X from './suggestions.webp';
import SUGGESTIONS_2X from './suggestions@2x.webp';
import SUGGESTIONS_3X from './suggestions@3x.webp';


export const IMAGES: ImagesMap = {
  ALGORITM: {
    src: ALGORITM_1X,
    srcSet: `${ALGORITM_1X}, ${ALGORITM_2X} 2x, ${ALGORITM_3X} 3x`
  },
  INSIGHTS: {
    src: INSIGHTS_1X,
    srcSet: `${INSIGHTS_1X}, ${INSIGHTS_2X} 2x, ${INSIGHTS_3X} 3x`
  },
  READINGS: {
    src: READINGS_1X,
    srcSet: `${READINGS_1X}, ${READINGS_2X} 2x, ${READINGS_3X} 3x`
  },
  SUGGESTIONS: {
    src: SUGGESTIONS_1X,
    srcSet: `${SUGGESTIONS_1X}, ${SUGGESTIONS_2X} 2x, ${SUGGESTIONS_3X} 3x`
  },
}