import { ImagesMap } from "core/interfaces/images";

import READINGS_1X from './reading.webp';
import READINGS_2X from './reading@2x.webp';
import READINGS_3X from './reading@3x.webp';

import TECHNOLOGY_1X from './technology.webp';
import TECHNOLOGY_2X from './technology@2x.webp';
import TECHNOLOGY_3X from './technology@3x.webp';

import INSIGHTS_1X from './insights.webp';
import INSIGHTS_2X from './insights@2x.webp';
import INSIGHTS_3X from './insights@3x.webp';

import RESULTS_1X from './results.webp';
import RESULTS_2X from './results@2x.webp';
import RESULTS_3X from './results@3x.webp';


export const IMAGES: ImagesMap = {
  READINGS: {
    src: READINGS_1X,
    srcSet: `${READINGS_1X}, ${READINGS_2X} 2x, ${READINGS_3X} 3x`
  },
  TECHNOLOGY: {
    src: TECHNOLOGY_1X,
    srcSet: `${TECHNOLOGY_1X}, ${TECHNOLOGY_2X} 2x, ${TECHNOLOGY_3X} 3x`
  },
  INSIGHTS: {
    src: INSIGHTS_1X,
    srcSet: `${INSIGHTS_1X}, ${INSIGHTS_2X} 2x, ${INSIGHTS_3X} 3x`
  },
  RESULTS: {
    src: RESULTS_1X,
    srcSet: `${RESULTS_1X}, ${RESULTS_2X} 2x, ${RESULTS_3X} 3x`
  },
}