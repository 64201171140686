import React from 'react';

import { t } from '@web-solutions/module-localization';

import { BaseFeedbacks, FEEDBACKS } from '../base';

import { IMAGES } from './images';

const tKey = 'sections.tarot_feedbacks';

export const FeedbacksTarot: React.FC = () => {
  const feedbacks = t(`${tKey}.sliders`, { returnObjects: true }) as any as FEEDBACKS;

  return (
    <BaseFeedbacks feedbacks={feedbacks} images={IMAGES} />
  )
}