type Zodiacs = {
  [key: string]: string
}
export const ZODIACS: Zodiacs = {
  ARIES: 'ARIES',
  TAURUS: 'TAURUS',
  GEMINI: 'GEMINI',
  CANCER: 'CANCER',
  LEO: 'LEO',
  VIRGO: 'VIRGO',
  LIBRA: 'LIBRA',
  SCORPIO: 'SCORPIO',
  SAGITTARIUS: 'SAGITTARIUS',
  CAPRICORN: 'CAPRICORN',
  AQUARIUS: 'AQUARIUS',
  PISCES: 'PISCES',
}

export const zodiacsStarts: { [key: string]: string } = {
  [ZODIACS.CAPRICORN]: '120',
  [ZODIACS.AQUARIUS]: '219',
  [ZODIACS.PISCES]: '320',
  [ZODIACS.ARIES]: '420',
  [ZODIACS.TAURUS]: '520',
  [ZODIACS.GEMINI]: '621',
  [ZODIACS.CANCER]: '722',
  [ZODIACS.LEO]: '822',
  [ZODIACS.VIRGO]: '921',
  [ZODIACS.LIBRA]: '1022',
  [ZODIACS.SCORPIO]: '1121',
  [ZODIACS.SAGITTARIUS]: '1221'
};