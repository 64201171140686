import React from 'react';

import classes from './style.module.scss';

interface Props {
  text: string;
}

export const Insight: React.FC<Props> = ({ text }) => (
  <div className={classes.insight}>
    <div className={classes.dot}>
      <div className={classes.blur}></div>
      <div className={classes.dot_mini}></div>
    </div>
    <p className={classes.text}>{text}</p>
  </div>
);