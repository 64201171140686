import _map from 'lodash/map';
import React from 'react';

import { t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import Tile from './components/tile';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'sections.more_horoscopes';

const url = process.env.REACT_APP_SUB_URL!;

const MoreHoroscopes: React.FC = () => {
  const types = t(`${tKey}.types`, { returnObjects: true }) as any as { [key: string]: string };

  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <Section className={classes.wrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      <ul className={classes.tiles} data-url={url}>
        {_map(types, (v, k) => (
          <Tile image={IMAGES[k]} title={v} key={k} onTileClick={handleClick} />
        ))}
      </ul>
    </Section>
  )
};

export default MoreHoroscopes;
