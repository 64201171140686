import React from 'react';
import { Helmet } from 'react-helmet';

import HoroscopesIntro from 'src/sections/intro/horoscopes';
import MoreHoroscopes from 'src/sections/more-horoscopes';
import { FaqHoroscopes } from 'src/sections/faq/horoscopes';
import { AboutHoroscopes } from 'src/sections/about/horoscopes';
import { FeedbacksHoroscopes } from 'src/sections/feedbacks/horoscopes';
import { Features } from 'src/sections/features/horoscopes';
import { Header } from 'src/sections/header/';
import HoroscopeToday from 'src/sections/horoscope-today';
import { Footer } from 'src/sections/footer';

import { PageWrapper } from 'src/components/page-wrapper';

export const DailyHoroscopesPage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Daily Horoscope Astrology - Read Your Today’s Prediction 💫</title>
        <meta property="og:title"
          content="Daily Horoscope Astrology - Read Your Today’s Prediction 💫" />
        <meta name="description" content="Astrological daily horoscopes based on birth chart data. Read tips and predictions on your love, health, and career. Written by top astrologers with your needs in mind." />
        <meta property="og:description" content="Astrological daily horoscopes based on birth chart data. Read tips and predictions on your love, health, and career. Written by top astrologers with your needs in mind. " />
      </Helmet>
      <PageWrapper>
        <Header />
        <HoroscopesIntro />
        <HoroscopeToday />
        <MoreHoroscopes />
        <Features />
        <AboutHoroscopes />
        <FaqHoroscopes />
        <FeedbacksHoroscopes />
        <Footer />
      </PageWrapper>
    </>
  )
}