import { ImagesMap } from "core/interfaces/images";

import SUSAN_1X from '../../palm-reading/images/susan.webp';
import SUSAN_2X from '../../palm-reading/images/susan@2x.webp';
import SUSAN_3X from '../../palm-reading/images/susan@3x.webp';

import DAVID_1X from '../../palm-reading/images/david.webp';
import DAVID_2X from '../../palm-reading/images/david@2x.webp';
import DAVID_3X from '../../palm-reading/images/david@3x.webp';

import ERIC_1X from '../../palm-reading/images/eric.webp';
import ERIC_2X from '../../palm-reading/images/eric@2x.webp';
import ERIC_3X from '../../palm-reading/images/eric@3x.webp';

import EMMA_1X from './emma.webp';
import EMMA_2X from './emma@2x.webp';
import EMMA_3X from './emma@3x.webp';

import ASHLEY_1X from './ashley.webp';
import ASHLEY_2X from './ashley@2x.webp';
import ASHLEY_3X from './ashley@3x.webp';

import RACHEL_1X from './rachel.webp';
import RACHEL_2X from './rachel@2x.webp';
import RACHEL_3X from './rachel@3x.webp';


export const IMAGES: ImagesMap = {
  EMMA: {
    src: EMMA_1X,
    srcSet: `${EMMA_1X}, ${EMMA_2X} 2x, ${EMMA_3X} 3x`,
  },
  ASHLEY: {
    src: ASHLEY_1X,
    srcSet: `${ASHLEY_1X}, ${ASHLEY_2X} 2x, ${ASHLEY_3X} 3x`,
  },
  RACHEL: {
    src: RACHEL_1X,
    srcSet: `${RACHEL_1X}, ${RACHEL_2X} 2x, ${RACHEL_3X} 3x`,
  },
  SUSAN: {
    src: SUSAN_1X,
    srcSet: `${SUSAN_1X}, ${SUSAN_2X} 2x, ${SUSAN_3X} 3x`,
  },
  DAVID: {
    src: DAVID_1X,
    srcSet: `${DAVID_1X}, ${DAVID_2X} 2x, ${DAVID_3X} 3x`,
  },
  ERIC: {
    src: ERIC_1X,
    srcSet: `${ERIC_1X}, ${ERIC_2X} 2x, ${ERIC_3X} 3x`,
  }
};
