import React from 'react';
import { Helmet } from 'react-helmet';

import CompatibilityIntro from 'src/sections/intro/compatibility';
import { FaqCompatibility } from 'src/sections/faq/compatibility';
import { AboutCompatibility } from 'src/sections/about/compatibility';
import { FeedbacksCompatibility } from 'src/sections/feedbacks/compatibility';
import { MarriageCompatibility } from 'src/sections/сompatibility';
import { Features } from 'src/sections/features/compatibility';
import { Header } from 'src/sections/header/';
import { Footer } from 'src/sections/footer';

import { PageWrapper } from 'src/components/page-wrapper';

export const CompatibilityPage: React.FC = () => {

  return (
    <>
      <Helmet>
        <title>Astrology Signs Compatibility - Is It Love or Just a Fling? 💕</title>
        <meta property="og:title"
          content="Astrology Signs Compatibility - Is It Love or Just a Fling? 💕" />
        <meta name="description" content="Soulmates, buddies, or a ticking bomb? Check out our astrology signs compatibility charts and discover if your love interest is the right match for you!" />
        <meta property="og:description" content="Soulmates, buddies, or a ticking bomb? Check out our astrology signs compatibility charts and discover if your love interest is the right match for you! " />
      </Helmet>
      <PageWrapper>
        <Header />
        <CompatibilityIntro />
        <MarriageCompatibility />
        <Features />
        <AboutCompatibility />
        <FaqCompatibility />
        <FeedbacksCompatibility />
        <Footer />
      </PageWrapper>
    </>
  )
}