import React from 'react';
import classNames from 'classnames';

import { t } from '@web-solutions/module-localization';

import classes from './style.module.scss';

interface Props {
  showMore: boolean;
  onButtonClick: () => void;
}

export const ShowMoreLessButton: React.FC<Props> = ({ showMore, onButtonClick }) => {
  const showMoreText = !showMore ? t(`sections.show_more`) : t(`sections.show_less`);
  return (
    <button type="button" className={classes.showMore} onClick={onButtonClick}>
      <div
        className={classNames(classes.showMoreText, {
          [classes.open]: showMore,
        })}
      >
        {showMoreText}
      </div>
    </button>
  )
}


