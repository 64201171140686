import { ZODIACS } from 'astroline-web/src/constants/zodiac';

import { ELEMENT } from '../compatibility-report/constants';

export const ANIMALS = [
  { years: [1953, 1965, 1977, 1989, 2001, 2013, 2025], animal: 'SNAKE' },
  { years: [1957, 1969, 1981, 1993, 2005, 2017, 2029], animal: 'ROOSTER' },
  { years: [1948, 1960, 1972, 1984, 1996, 2008, 2020], animal: 'RAT' },
  { years: [1951, 1963, 1975, 1987, 1999, 2011, 2023], animal: 'RABBIT' },
  { years: [1955, 1967, 1979, 1991, 2003, 2015, 2027], animal: 'SHEEP' },
  { years: [1956, 1969, 1980, 1992, 2004, 2016, 2028], animal: 'MONKEY' },
  { years: [1950, 1962, 1974, 1986, 1998, 2010, 2022], animal: 'TIGER' },
  { years: [1958, 1970, 1982, 1994, 2006, 2018, 2030], animal: 'DOG' },
  { years: [1959, 1971, 1983, 1995, 2007, 2019, 2031], animal: 'PIG' },
  { years: [1952, 1964, 1976, 1988, 2000, 2012, 2024], animal: 'DRAGON' },
  { years: [1949, 1961, 1973, 1985, 1997, 2009, 2021], animal: 'BULL' },
  { years: [1954, 1966, 1978, 1990, 2002, 2014, 2026], animal: 'HORSE' },
];

enum Planet {
  SUN = 'sun',
  MOON = 'moon',
  MERCURY = 'mercury',
  VENUS = 'venus',
  MARS = 'mars',
  JUPITER = 'jupiter',
  SATURN = 'saturn',
  URANUS = 'uranus',
};

export const PLANETS = {
  [ZODIACS.LEO]: Planet.SUN,
  [ZODIACS.CANCER]: Planet.MOON,
  [ZODIACS.GEMINI]: Planet.MERCURY,
  [ZODIACS.TAURUS]: Planet.VENUS,
  [ZODIACS.ARIES]: Planet.MARS,
  [ZODIACS.SAGITTARIUS]: Planet.JUPITER,
  [ZODIACS.CAPRICORN]: Planet.SATURN,
  [ZODIACS.AQUARIUS]: Planet.URANUS,
  [ZODIACS.PISCES]: Planet.JUPITER,
  [ZODIACS.SCORPIO]: Planet.MARS,
  [ZODIACS.VIRGO]: Planet.MERCURY,
  [ZODIACS.LIBRA]: Planet.VENUS,
};

export const ELEMENTS = {
  [ZODIACS.LEO]: ELEMENT.FIRE,
  [ZODIACS.CANCER]: ELEMENT.WATER,
  [ZODIACS.GEMINI]: ELEMENT.AIR,
  [ZODIACS.TAURUS]: ELEMENT.EARTH,
  [ZODIACS.ARIES]: ELEMENT.FIRE,
  [ZODIACS.SAGITTARIUS]: ELEMENT.FIRE,
  [ZODIACS.CAPRICORN]: ELEMENT.EARTH,
  [ZODIACS.AQUARIUS]: ELEMENT.AIR,
  [ZODIACS.PISCES]: ELEMENT.WATER,
  [ZODIACS.SCORPIO]: ELEMENT.WATER,
  [ZODIACS.VIRGO]: ELEMENT.EARTH,
  [ZODIACS.LIBRA]: ELEMENT.AIR,
}

export const MATCHES = {
  [ZODIACS.LEO]: ZODIACS.SAGITTARIUS,
  [ZODIACS.CANCER]: ZODIACS.TAURUS,
  [ZODIACS.GEMINI]: ZODIACS.AQUARIUS,
  [ZODIACS.TAURUS]: ZODIACS.CANCER,
  [ZODIACS.ARIES]: ZODIACS.AQUARIUS,
  [ZODIACS.SAGITTARIUS]: ZODIACS.LEO,
  [ZODIACS.CAPRICORN]: ZODIACS.TAURUS,
  [ZODIACS.AQUARIUS]: ZODIACS.GEMINI,
  [ZODIACS.PISCES]: ZODIACS.CANCER,
  [ZODIACS.SCORPIO]: ZODIACS.CANCER,
  [ZODIACS.VIRGO]: ZODIACS.TAURUS,
  [ZODIACS.LIBRA]: ZODIACS.GEMINI,
} 
