import React from 'react';

import { tm, t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { useRemoteConfig } from 'src/hooks/use-remote-config';

import { SubButton } from '../../components/sub-button';

import { IMAGES } from './images';

import classes from './style.module.scss';

const tKey = 'sections.intro';

export const Intro: React.FC = () => {
  const { introSection } = useRemoteConfig();

  return (
    <Section className={classes.intro}>
      <Title level='h1' className={classes.title}>
        {tm(introSection?.title, `${tKey}.title`)}
      </Title>
      <main>
        <div className={classes.content}>
          <p className={classes.subtitle}>{tm(introSection?.subtitle, `${tKey}.subtitle`)}</p>
          <SubButton text={t(`sections.button_text`)} className={classes.sub_button} />
        </div>
        <img src={IMAGES.PIC.src} srcSet={IMAGES.PIC.srcSet} alt='' />
      </main>
    </Section>
  );
};
