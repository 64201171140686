import { ZODIACS } from 'astroline-web/src/constants/zodiac';

import cancer from '../icons/cancer.svg';
import gemini from '../icons/gemini.svg';
import aries from '../icons/aries.svg';
import aquarius from '../icons/aquarius.svg';
import leo from '../icons/leo.svg';
import capricorn from '../icons/capricorn.svg';
import libra from '../icons/libra.svg';
import pisces from '../icons/pisces.svg';
import scorpio from '../icons/scorpio.svg';
import sagittarius from '../icons/sagittarius.svg';
import taurus from '../icons/taurus.svg';
import virgo from '../icons/virgo.svg';

export enum ELEMENT {
  AIR = 'air',
  EARTH = 'earth',
  FIRE = 'fire',
  WATER = 'water'
}

export const ASPECTS_ID = {
  LOVE: 'love',
  MARRIAGE: 'marriage',
  TRUST: 'trust',
  TEAMWORK: 'teamwork',
  COMMUNICATION: 'communication',
  HUMOR: 'humor',
}

export const ASPECTS = [
  {
    description: 'compatibility_aspects_love_description',
    percent: 'compatibility_aspects_love_percent',
    color: '#F27067',
    id: ASPECTS_ID.LOVE,
  },
  {
    description: 'compatibilityAspectsCommunicationLevel',
    percent: 'compatibility_aspects_communication_percent',
    color: '#C583FA',
    id: ASPECTS_ID.COMMUNICATION,
  },
  {
    description: 'compatibilityAspectsTrustLevel',
    percent: 'compatibility_aspects_trust_percent',
    color: '#F9D05D',
    id: ASPECTS_ID.TRUST,
  },
  {
    description: 'compatibility_aspects_marriage_description',
    percent: 'compatibility_aspects_marriage_percent',
    color: '#19CEBB',
    id: ASPECTS_ID.MARRIAGE,
  },
];

export const ZODIAC_ICONS = {
  [ZODIACS.ARIES]: aries,
  [ZODIACS.TAURUS]: taurus,
  [ZODIACS.PISCES]: pisces,
  [ZODIACS.AQUARIUS]: aquarius,
  [ZODIACS.CAPRICORN]: capricorn,
  [ZODIACS.SAGITTARIUS]: sagittarius,
  [ZODIACS.SCORPIO]: scorpio,
  [ZODIACS.LIBRA]: libra,
  [ZODIACS.VIRGO]: virgo,
  [ZODIACS.LEO]: leo,
  [ZODIACS.CANCER]: cancer,
  [ZODIACS.GEMINI]: gemini,
}