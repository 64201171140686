import React, { useState } from 'react';
import classNames from 'classnames';

import {
  TICKET_PRIORITY,
  postTicket,
  type IResponseTicketData
} from '@wowmaking/helpdesk';

import { t } from '@web-solutions/module-localization';

import { Title, Button, Input, Modal } from '@web-solutions/core/ui-elements';

import { useHeight } from '@web-solutions/core/hooks/use-height';

import { ReactComponent as ArrowIcon } from 'src/shared/icons/arrow.svg';

import { ReactComponent as InfoIcon } from './icons/info.svg';

import { useGetDeviceInfoData } from './hooks';

import classes from './style.module.scss';

interface Props {
  active: boolean;
  onCloseModal: () => void;
  onCloseModalAfterSubmit: () => void;
}
type InitialData = {
  [key: string]: string;
}

const tKey = 'contact_modal';
const PROJECT_SUPPORT_SUBJECT = '[Astroline WEB] ';
const TEAM_ID = 'f432ea87-bc31-4b82-80db-82673a4bc825'

const initialData: InitialData = {
  name: '',
  email: '',
  category: '',
  subject: '',
  message: '',
}

const ContactModal: React.FC<Props> = ({ active, onCloseModal, onCloseModalAfterSubmit }) => {
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [data, setData] = useState(initialData);

  const containerHeight = useHeight();

  const deviceInfoData = useGetDeviceInfoData();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, subject, message, name, category } = data;

    postTicket({
      ticket: {
        subject: PROJECT_SUPPORT_SUBJECT + subject,
        mail: email,
        comment: { body: message },
        custom_fields: { ...deviceInfoData, category },
        requester: { name: name || 'User', email },
        priority: TICKET_PRIORITY.high,
        assignment: {
          agentID: '',
          teamID: TEAM_ID
        },
      },
    }).then((data: IResponseTicketData) => {
      setData(initialData);
    }).catch((err) => {
      console.error(err);
    })
  }

  const handleDropdownClick = () => {
    setActiveDropdown(!activeDropdown);
  }

  const handleSelectCategory = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;
    setData({ ...data, category: target.id });
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  }

  return (
    <Modal
      className={classes.container}
      isOpen={active}
      overlayClassName={classes.overlay}
    >
      <form className={classes.form} onSubmit={handleSubmit} style={{ height: containerHeight }}>
        <header>
          <button className={classes.arrow_button} onClick={() => onCloseModal()}>
            <ArrowIcon className={classes.arrow} />
          </button>
          <Title level='h2' className={classes.title}>{t(`${tKey}.title`)}</Title>
        </header>

        <div className={classes.tooltip}>
          <InfoIcon />
          <p className={classes.desc}>{t(`${tKey}.desc`)}</p>
        </div>

        <div className={classes.field_wrap}>
          <p className={classes.name}>{t(`${tKey}.fields.name.title`)}</p>
          <Input
            autoComplete="off"
            type='text'
            name='name'
            value={data.name}
            placeholder={t(`${tKey}.fields.name.placeholder`)}
            className={classes.field}
            onChange={handleChange}
          />
        </div>
        <div className={classes.field_wrap}>
          <p className={classes.name}>{t(`${tKey}.fields.email.title`)}</p>
          <Input
            autoComplete="off"
            type='email'
            name='email'
            value={data.email}
            placeholder={`${t(`${tKey}.fields.email.placeholder`)}@gmail.com`}
            className={classes.field}
            onChange={handleChange} />
        </div>
        <div className={classes.field_wrap}>
          <p className={classes.name}>{t(`${tKey}.fields.category.title`)}</p>
          <div className={classNames(classes.field, {
            [classes.active]: activeDropdown
          })}
            onClick={handleDropdownClick}>
            <p className={classNames(classes.placeholder, {
              [classes.another_color]: data.category
            })}>
              {data.category ? t(`${tKey}.fields.category.dropdown.${data.category}`) : t(`${tKey}.fields.category.placeholder`)}
            </p>
            <div className={classes.dropdown} onClick={handleSelectCategory}>
              <p id='BUG' >{t(`${tKey}.fields.category.dropdown.BUG`)}</p>
              <p id='FEEDBACK'>{t(`${tKey}.fields.category.dropdown.FEEDBACK`)}</p>
              <p id='SUBSCRIPTION'>{t(`${tKey}.fields.category.dropdown.SUBSCRIPTION`)}</p>
              <p id='OTHER'>{t(`${tKey}.fields.category.dropdown.OTHER`)}</p>
            </div>
          </div>
        </div>
        <div className={classes.field_wrap}>
          <p className={classes.name}>{t(`${tKey}.fields.subject.title`)}</p>
          <Input
            autoComplete="off"
            type='text'
            name='subject'
            value={data.subject}
            placeholder={t(`${tKey}.fields.subject.placeholder`)}
            className={classes.field}
            onChange={handleChange}
          />
        </div>
        <div className={classes.field_wrap}>
          <p className={classes.name}>{t(`${tKey}.fields.message.title`)}</p>
          <textarea
            placeholder={t(`${tKey}.fields.message.placeholder`)}
            name='message'
            value={data.message}
            className={classNames(classes.field, classes.message)}
            onChange={handleChange}
          />
        </div>
        <Button
          onClick={() => onCloseModalAfterSubmit()}
          title={t(`${tKey}.button_text`)}
          className={classes.button}
          type='submit'
          disabled={data.email && data.category && data.message ? false : true}
        />
      </form>
    </Modal>
  )
}
export default ContactModal;