import { ImagesMap } from 'core/interfaces/images';

import HOROSCOPES_1X from './pic.webp';
import HOROSCOPES_2X from './pic@2x.webp';
import HOROSCOPES_3X from './pic@3x.webp';


export const IMAGES: ImagesMap = {
  HOROSCOPES: {
    src: HOROSCOPES_1X,
    srcSet: `${HOROSCOPES_1X}, ${HOROSCOPES_2X} 2x, ${HOROSCOPES_3X} 3x`,
  },
};