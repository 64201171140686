import React from 'react';

import { t } from '@web-solutions/module-localization';

import { ImageSource } from 'core/interfaces/images';

import { Block } from 'core/ui-elements';

import classes from './style.module.scss';

interface Props {
  image: ImageSource,
  zodiacName: string,
  date: string,
  text: string,
  onTileClick: () => void,
}

const Tile: React.FC<Props> = ({ image, zodiacName, date, text, onTileClick }) => {
  return (
    <Block className={classes.tile}>
      <div className={classes.top_part}>
        <img src={image.src} alt='sign' className={classes.image}/>
        <div className={classes.top_rigth_part}>
          <span className={classes.zodiac_name}>{zodiacName}</span>
          <span className={classes.date}>{date}</span>
        </div>
      </div>
      <p className={classes.text}>{text}</p>
      <button className={classes.link} onClick={onTileClick}>{t('sections.horoscope_today.read_more')}</button>
    </Block>
  )
};

export default Tile;
