import _map from 'lodash/map';
import React, { useState } from 'react';

import 'swiper/css';
import 'swiper/css/navigation';

import { getPeriod } from 'astroline-web/src/utils/zodiacConverter';

import { t, T } from '@web-solutions/module-localization';

import { Title, Section, Swiper } from 'core/ui-elements';

import { useResize } from 'src/hooks/use-resize';

import { SubButton } from 'src/sections/components/sub-button';

import { GetResultButton } from '../components/get-result-button';

import { SliderTile } from './components/slider-tile';
import { SliderController } from './components/controller';
import { Result } from './components/result';

import IMAGES from './images';
import { ReactComponent as Plus } from './images/plus.svg'

import classes from './style.module.scss';

const tKey = 'sections.compatibility';

export const MarriageCompatibility: React.FC = () => {
  const [userSign, setUserSign] = useState<string | null>(null);
  const [crushSign, setCrushSign] = useState<string | null>(null);
  const [showResult, setShowResult] = useState(false);
  const isDesctop = useResize();
  const zodiacs = t('zodiac', { returnObjects: true }) as any as { [key: string]: string };

  const renderSlides = (isUser: boolean): React.ReactNode[] => {
    return _map(zodiacs, (v, k) => (
      <SliderTile
        date={getPeriod(k)}
        zodiacName={t(`zodiac.${k}`)}
        image={IMAGES[k]}
        key={k}
        onZodiacClick={() => {
          if (isUser) {
            setUserSign(k);
          } else {
            setCrushSign(k);
          }
        }}
        active={(isUser && userSign === k) || (!isUser && crushSign === k)}
      />
    ));
  };

  const renderUserSlides = (): React.ReactNode[] => renderSlides(true);
  const renderCrushSlides = (): React.ReactNode[] => renderSlides(false);

  return (
    <Section className={classes.wrapper}>
      <Title level='h2' className={classes.title}>
        {t(`${tKey}.title`)}
      </Title>
      {showResult ?
        <>
          <Result
            title={t(`${tKey}.glance`)}
            text={
              <T
                k={`${tKey}.description`}
                tOptions={{ userSign: `${t(`zodiac.${userSign}`)}`, crushSign: `${t(`zodiac.${crushSign}`)}` }}
              />
            }
            overlay_text={t(`${tKey}.overlay_text`)}
            userSign={userSign!}
            crushSign={crushSign!}
          />
        </>
        :
        <div className={classes.swiper_wrap}>
          <Swiper
            initialSlide={2}
            spaceBetween={10}
            slidesPerView={isDesctop ? 5.5 : 3.3}
            slides={renderUserSlides()}
            pagination={false}
          >
            <SliderController text={t(`${tKey}.your_sign`)} />
          </Swiper>
          <Plus className={classes.plus} />

          <Swiper
            initialSlide={2}
            spaceBetween={10}
            slidesPerView={isDesctop ? 5.5 : 3.3}
            slides={renderCrushSlides()}
            pagination={false}
          >
            <SliderController text={t(`${tKey}.crush_sign`)} />
          </Swiper>
        </div>
      }
      {showResult ?
        <SubButton text={t('sections.read_more_btn')} className={classes.sub_button} />
        :
        <GetResultButton
          text={t('sections.get_result_btn')}
          onButtonClick={() => setShowResult(true)}
          active={userSign && crushSign ? true : false}
        />
      }
    </Section>
  )
}