import React, { useState } from 'react';
import classNames from 'classnames';

import { T, t } from '@web-solutions/module-localization';

import { Title, Section } from 'core/ui-elements';

import { ShowMoreLessButton } from '../../components/show-more-less-button';

import classes from './style.module.scss';

const link_href = "https://astroline.today/";

type Content = { title?: string, texts: string[] }[];
type Points = { title: string, text: string }[];

interface Props {
  h2IndexesTopPart: number[];
  h2IndexesBottomPart?: number[];
  content: any;
  title: string;
}

export const About: React.FC<Props> = ({ h2IndexesTopPart, h2IndexesBottomPart, content, title }) => {
  const [showMore, setShowMore] = useState(false);
  const content_top_part: Content = content?.content_top_part;
  const content_bottom_part: Content = content?.content_bottom_part;
  const points: Points = content?.content_middle_part?.points;

  return (
    <Section className={classes.container}>
      <Title level='h2' className={classes.title}>{title}</Title>
      <div className={classNames(classes.content, { [classes.hidden]: !showMore })}>
        {content_top_part.map((section, index) => {
          let isH2 = h2IndexesTopPart.includes(index);
          return (
            <div key={index}>
              {section.title && (
                <Title
                  level={isH2 ? 'h2' : 'h3'}
                  className={classNames(classes.secondary_title, { [classes.h2_title]: isH2 })}>
                  {section.title}
                </Title>
              )}
              {section.texts.map((text, index) =>
                <p className={classes.text} key={index}>
                  <T
                    k={text}
                    tOptions={{ linkText: t('sections.about.link_text') }}
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    components={{ a: <a href={link_href} /> }}
                  />
                </p>
              )}
            </div>
          )
        })}
        {h2IndexesBottomPart && (
          <>
            <div itemScope itemType="https://schema.org/HowTo">
              <Title level='h3' itemProp="name" className={classes.h3_title}>{content.content_middle_part.title}</Title>
              {points?.map((point, index) => (
                <div key={index} itemProp="step" itemScope itemType="https://schema.org/HowToStep">
                  <p className={classNames(classes.text, classes.bold)} itemProp="name">{`${index + 1}) ${point.title}`}</p>
                  <p className={classes.text} itemProp="text">{point.text}</p>
                </div>
              ))}
            </div>
            {content_bottom_part?.map((section, index) => {
              let isH2 = h2IndexesBottomPart?.includes(index);
              return (
                <div key={index}>
                  {section.title && (
                    <Title
                      level={isH2 ? 'h2' : 'h3'}
                      className={classNames(classes.secondary_title, { [classes.h2_title]: isH2 })}>
                      {section.title}
                    </Title>
                  )}
                  {section.texts.map((text, index) => <p className={classes.text} key={index}>{text}</p>)}
                </div>
              )
            }
            )}
          </>
        )}
      </div>
      <ShowMoreLessButton onButtonClick={() => setShowMore(!showMore)} showMore={showMore} />
    </Section>
  )
}