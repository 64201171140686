import { ImagesMap } from "core/interfaces/images";

import LAURA_1X from './laura.webp';
import LAURA_2X from './laura@2x.webp';
import LAURA_3X from './laura@3x.webp';

import MARK_1X from './mark.webp';
import MARK_2X from './mark@2x.webp';
import MARK_3X from './mark@3x.webp';

import KAREN_1X from './karen.webp';
import KAREN_2X from './karen@2x.webp';
import KAREN_3X from './karen@3x.webp';

import CLAIRE_1X from './claire.webp';
import CLAIRE_2X from './claire@2x.webp';
import CLAIRE_3X from './claire@3x.webp';

import JENNY_1X from './jenny.webp';
import JENNY_2X from './jenny@2x.webp';
import JENNY_3X from './jenny@3x.webp';

import MARTIN_1X from './martin.webp';
import MARTIN_2X from './martin@2x.webp';
import MARTIN_3X from './martin@3x.webp';

export const IMAGES: ImagesMap = {
  LAURA: {
    src: LAURA_1X,
    srcSet: `${LAURA_1X}, ${LAURA_2X} 2x, ${LAURA_3X} 3x`,
  },
  MARK: {
    src: MARK_1X,
    srcSet: `${MARK_1X}, ${MARK_2X} 2x, ${MARK_3X} 3x`,
  },
  KAREN: {
    src: KAREN_1X,
    srcSet: `${KAREN_1X}, ${KAREN_2X} 2x, ${KAREN_3X} 3x`,
  },
  CLAIRE: {
    src: CLAIRE_1X,
    srcSet: `${CLAIRE_1X}, ${CLAIRE_2X} 2x, ${CLAIRE_3X} 3x`,
  },
  MARTIN: {
    src: MARTIN_1X,
    srcSet: `${MARTIN_1X}, ${MARTIN_2X} 2x, ${MARTIN_3X} 3x`,
  },
  JENNY: {
    src: JENNY_1X,
    srcSet: `${JENNY_1X}, ${JENNY_2X} 2x, ${JENNY_3X} 3x`,
  },
};
