import _ from 'lodash';

const NOMINATIM = 'https://nominatim.astroline.app/nominatim';

let controller = new AbortController();

const formatAddress = address => {
  const country = address?.country ? address.country : '';

  const state = address?.state ? `${address.state}, ` : '';

  const hamlet = address?.hamlet ? `${address.hamlet}, ` : '';
  const city = address?.city ? `${address.city}, ` : '';
  const town = address?.town ? `${address.town}, ` : '';

  return `${hamlet || city || town}${state}${country}`.trim();
};

export function searchAddress(s) {
  controller.abort();
  controller = new AbortController();
  const searchString = s.split(' ').join('+');
  const path = `${NOMINATIM}/search?q=${searchString}&format=json&addressdetails=1&limit=5`;
  return fetch(path, {
    method: 'GET',
    signal: controller.signal,
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then(r => _.map(r, i => ({ ...i, label: formatAddress(i.address) })));
}

export function getApproximateLocation() {
  return fetch('https://spoten-api.digisoapps.com/api/v3/profile/approximate-location')
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((response) => {
      return fetch(`${NOMINATIM}/reverse?lat=${response.latitude}&lon=${response.longitude}&format=json`)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((response) => {
      return {
        lat: response.lat,
        lon: response.lon,
        label: formatAddress(response.address),
      };
    });
}
