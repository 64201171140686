import { ImagesMap } from "core/interfaces/images";

import EXPERT_1X from './expert.webp';
import EXPERT_2X from './expert@2x.webp';
import EXPERT_3X from './expert@3x.webp';

import GUIDANCE_1X from './guidance.webp';
import GUIDANCE_2X from './guidance@2x.webp';
import GUIDANCE_3X from './guidance@3x.webp';

import ANALUSIS_1X from './analusis.webp';
import ANALUSIS_2X from './analusis@2x.webp';
import ANALUSIS_3X from './analusis@3x.webp';

import UPDATES_1X from './updates.webp';
import UPDATES_2X from './updates@2x.webp';
import UPDATES_3X from './updates@3x.webp';


export const IMAGES: ImagesMap = {
  EXPERT: {
    src: EXPERT_1X,
    srcSet: `${EXPERT_1X}, ${EXPERT_2X} 2x, ${EXPERT_3X} 3x`
  },
  GUIDANCE: {
    src: GUIDANCE_1X,
    srcSet: `${GUIDANCE_1X}, ${GUIDANCE_2X} 2x, ${GUIDANCE_3X} 3x`
  },
  ANALUSIS: {
    src: ANALUSIS_1X,
    srcSet: `${ANALUSIS_1X}, ${ANALUSIS_2X} 2x, ${ANALUSIS_3X} 3x`
  },
  UPDATES: {
    src: UPDATES_1X,
    srcSet: `${UPDATES_1X}, ${UPDATES_2X} 2x, ${UPDATES_3X} 3x`
  },
}