import { ImagesMap } from 'core/interfaces/images';

import TAROT_1X from './pic.webp';
import TAROT_2X from './pic@2x.webp';
import TAROT_3X from './pic@3x.webp';


export const IMAGES: ImagesMap = {
  TAROT: {
    src: TAROT_1X,
    srcSet: `${TAROT_1X}, ${TAROT_2X} 2x, ${TAROT_3X} 3x`,
  },
};