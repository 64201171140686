import AQUARIUS from 'astroline-web/src/shared/images/zodiac-sign/aquarius.svg'
import ARIES from 'astroline-web/src/shared/images/zodiac-sign/aries.svg';
import CANCER from 'astroline-web/src/shared/images/zodiac-sign/cancer.svg';
import CAPRICORN from 'astroline-web/src/shared/images/zodiac-sign/capricorn.svg';
import GEMINI from 'astroline-web/src/shared/images/zodiac-sign/gemini.svg';
import LEO from 'astroline-web/src/shared/images/zodiac-sign/leo.svg';
import LIBRA from 'astroline-web/src/shared/images/zodiac-sign/libra.svg';
import PISCES from 'astroline-web/src/shared/images/zodiac-sign/pisces.svg';
import SAGITTARIUS from 'astroline-web/src/shared/images/zodiac-sign/sagittarius.svg';
import SCORPIO from 'astroline-web/src/shared/images/zodiac-sign/scorpio.svg';
import TAURUS from 'astroline-web/src/shared/images/zodiac-sign/taurus.svg';
import VIRGO from 'astroline-web/src/shared/images/zodiac-sign/virgo.svg';

import { ImagesMap } from 'core/interfaces/images';

const IMAGES: ImagesMap = {
  AQUARIUS: {
    src: AQUARIUS,
  },
  ARIES: {
    src: ARIES,
  },
  CANCER: {
    src: CANCER,
  },
  CAPRICORN: {
    src: CAPRICORN,
  },
  GEMINI: {
    src: GEMINI,
  },
  LEO: {
    src: LEO,
  },
  LIBRA: {
    src: LIBRA,
  },
  PISCES: {
    src: PISCES,
  },
  SAGITTARIUS: {
    src: SAGITTARIUS,
  },
  SCORPIO: {
    src: SCORPIO,
  },
  TAURUS: {
    src: TAURUS,
  },
  VIRGO: {
    src: VIRGO,
  },
};

export default IMAGES;