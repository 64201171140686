import { ImagesMap } from "core/interfaces/images";

import EXPERT_1X from './expert.webp';
import EXPERT_2X from './expert@2x.webp';
import EXPERT_3X from './expert@3x.webp';

import GUIDANCE_1X from './guidance.webp';
import GUIDANCE_2X from './guidance@2x.webp';
import GUIDANCE_3X from './guidance@3x.webp';

import ADVICE_1X from './advice.webp';
import ADVICE_2X from './advice@2x.webp';
import ADVICE_3X from './advice@3x.webp';

import READINGS_1X from './readings.webp';
import READINGS_2X from './readings@2x.webp';
import READINGS_3X from './readings@3x.webp';


export const IMAGES: ImagesMap = {
  EXPERT: {
    src: EXPERT_1X,
    srcSet: `${EXPERT_1X}, ${EXPERT_2X} 2x, ${EXPERT_3X} 3x`
  },
  GUIDANCE: {
    src: GUIDANCE_1X,
    srcSet: `${GUIDANCE_1X}, ${GUIDANCE_2X} 2x, ${GUIDANCE_3X} 3x`
  },
  ADVICE: {
    src: ADVICE_1X,
    srcSet: `${ADVICE_1X}, ${ADVICE_2X} 2x, ${ADVICE_3X} 3x`
  },
  READINGS: {
    src: READINGS_1X,
    srcSet: `${READINGS_1X}, ${READINGS_2X} 2x, ${READINGS_3X} 3x`
  },
}