import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import Analytics from '@web-solutions/module-analytics';

import { t } from '@web-solutions/module-localization';

import { SideBar } from 'core/ui-elements';
import { Theme } from 'core/constants/theme';
import { useRemoteConfig } from 'core/hooks/use-remote-config';

//@ts-ignore
import { SUPPORT_EMAIL } from 'src/constants/general';

import classes from './style.module.scss';

const tKey = 'core.terms_info';

interface Props {
  theme?: Theme,
  show: boolean,
  onContactClick?: () => void,
  onOpenMenu?: (open: boolean) => void,
  customItem?: React.ReactNode,
  className?: string,
}

type Links = { [key: string]: string };

export const TermsMenu: React.FC<Props> = ({ theme, show, onContactClick, onOpenMenu, customItem, className }) => {
  const [open, setOpen] = useState(false);
  const { policyLinks } = useRemoteConfig();
  const onOpenMenuRef = useRef<(open: boolean) => void>();
  onOpenMenuRef.current = onOpenMenu;

  useEffect(() => {
    onOpenMenuRef.current?.(open);
  }, [open]);

  const links: Links = {
    privacy_policy: policyLinks?.privacy,
    terms: policyLinks?.termsOfUse,
    billing_terms: policyLinks?.billingTerms,
  };

  const handleVisibilityChange = () => {
    Analytics.trackEvent('terms_menu', open ? 'close' : 'open');
    setOpen(!open);
  }

  const handleContactClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onContactClick) {
      e.preventDefault();
      onContactClick();
    } else {
      handleClick(e);
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    //@ts-ignore
    Analytics.trackEvent(`terms_menu_${e.target?.id}`, 'click');
    setOpen(!open);
  }

  return (
    <div className={classNames(classes.pageContainer, className)}>
      {show && (
        <SideBar
          onVisibilityChange={handleVisibilityChange}
          visible={open}
          theme={theme}
        >
          <div className={classes.content}>
            <a href={`mailto:${SUPPORT_EMAIL}`} id='contact_us' target="__blank" onClick={handleContactClick}>
              {t(`${tKey}.contact_us`)}
            </a>
            {Object.keys(links).map((key) => (
              <a key={key} id={key} href={links[key]} target="__blank" onClick={handleClick}>
                {t(`${tKey}.${key}`)}
              </a>
            ))}
            <a href={policyLinks?.moneyBack} id='money_back' target="__blank" onClick={handleClick}>
              {t(`${tKey}.money_back`)}
            </a>
            {customItem}
          </div>
        </SideBar>
      )}
    </div>
  );
};
