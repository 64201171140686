import { ImagesMap } from "core/interfaces/images";

import LAUREN_1X from '../../compatibility/images/lauren.webp';
import LAUREN_2X from '../../compatibility/images/lauren@2x.webp';
import LAUREN_3X from '../../compatibility/images/lauren@3x.webp';

import LISA_1X from '../../compatibility/images/lisa.webp';
import LISA_2X from '../../compatibility/images/lisa@2x.webp';
import LISA_3X from '../../compatibility/images/lisa@3x.webp';

import CHRIS_1X from '../../compatibility/images/chris.webp';
import CHRIS_2X from '../../compatibility/images/chris@2x.webp';
import CHRIS_3X from '../../compatibility/images/chris@3x.webp';

import SARAH_1X from './sarah.webp';
import SARAH_2X from './sarah@2x.webp';
import SARAH_3X from './sarah@3x.webp';

import STEVE_1X from './steve.webp';
import STEVE_2X from './steve@2x.webp';
import STEVE_3X from './steve@3x.webp';

import JAMES_1X from './james.webp';
import JAMES_2X from './james@2x.webp';
import JAMES_3X from './james@3x.webp';

export const IMAGES: ImagesMap = {
  SARAH: {
    src: SARAH_1X,
    srcSet: `${SARAH_1X}, ${SARAH_2X} 2x, ${SARAH_3X} 3x`,
  },
  STEVE: {
    src: STEVE_1X,
    srcSet: `${STEVE_1X}, ${STEVE_2X} 2x, ${STEVE_3X} 3x`,
  },
  JAMES: {
    src: JAMES_1X,
    srcSet: `${JAMES_1X}, ${JAMES_2X} 2x, ${JAMES_3X} 3x`,
  },
  LAUREN: {
    src: LAUREN_1X,
    srcSet: `${LAUREN_1X}, ${LAUREN_2X} 2x, ${LAUREN_3X} 3x`,
  },
  LISA: {
    src: LISA_1X,
    srcSet: `${LISA_1X}, ${LISA_2X} 2x, ${LISA_3X} 3x`,
  },
  CHRIS: {
    src: CHRIS_1X,
    srcSet: `${CHRIS_1X}, ${CHRIS_2X} 2x, ${CHRIS_3X} 3x`,
  }
};
