import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { t } from '@web-solutions/module-localization';

import Analytics from '@web-solutions/module-analytics';

import { EVENT_ACTION } from 'core/constants/general';
import { Theme } from 'core/constants/theme';
import { LinkButton } from 'core/ui-elements';
import { TermsMenu } from 'core/containers/terms-menu';

import { APP_NAME, HELP_CENTER_LINK } from 'src/constants/general';

import ContactModal from '../components/contact-modal';
import { SuccessPopup } from '../components/success-popup';

import IMAGES from './images';

import classes from './style.module.scss';

const app_link = 'https://app.astroline.today/'
const query_param = 'source=home';

export const Header: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);


  const handleSignInClick = () => {
    Analytics.trackEvent('sign_in', EVENT_ACTION.CLICK);
  }

  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleOpenMenu = (isOpen: boolean) => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  const handleFaqClick = () => {
    Analytics.trackEvent(`terms_menu_faq`, EVENT_ACTION.CLICK);
    document.body.style.overflow = 'auto';
  }

  const handleHelpCenterClick = () => {
    Analytics.trackEvent(`terms_menu_help_center`, EVENT_ACTION.CLICK);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setShowSuccessPopup(true);
  }

  return (
    <>
      <header className={classes.header}>
        <div className={classes.container}>
          <LinkButton className={classes.sign_in} onClick={handleSignInClick}>
            <a href={`${app_link}?${query_param}`}>{t(`sign_in`)}</a>
          </LinkButton>
          <Link to='/' className={classes.logo}>
            <img
              src={IMAGES.LOGO.src}
              srcSet={IMAGES.LOGO.srcSet}
              alt='logo'
              className={classes.image}
            />
            <span className={classes.title}>{APP_NAME}</span>
          </Link>
        </div>
      </header>
      <aside className={classes.menu}>
        <TermsMenu
          show
          theme={Theme.dark}
          onContactClick={handleOpenModal}
          onOpenMenu={handleOpenMenu}
          customItem={
            <>
              <Link to='/faq' onClick={handleFaqClick}>{t(`faq_item`)}</Link>
              <a href={HELP_CENTER_LINK} className={classes.helpCenter} target="__blank" onClick={handleHelpCenterClick}>
                {t(`sections.footer.help_center`)}
              </a>
            </>
          }
          className={classes.auto_max_width}
        />
      </aside>
      <ContactModal
        active={showModal}
        onCloseModal={() => setShowModal(false)}
        onCloseModalAfterSubmit={handleCloseModal}
      />
      <SuccessPopup
        active={showSuccessPopup}
        onClosePopup={() => setShowSuccessPopup(false)}
      />
    </>
  );
};
