import React from 'react';
import classNames from 'classnames';
//@ts-ignore
import PlacePicker from 'astroline-web/src/components/place-picker';

import { t } from '@web-solutions/module-localization';

import { Input } from 'core/ui-elements';

import { InitialData, Place } from '../..';

import classes from './style.module.scss';

interface Props {
  data: InitialData,
  place: Place | null,
  onInputChange: (e: any) => void,
  onPlaceChange: (value: any) => void,
  validation: { [key in 'place' | 'month' | 'day' | 'year' | 'hours' | 'minutes']: boolean };
}

const tKey = 'sections.birth_chart';

export const Form: React.FC<Props> = ({
  data,
  place,
  onPlaceChange,
  onInputChange,
  validation
}) => {
  return (
    <form>
      <div className={classes.field_wrap} >
        <p className={classes.name}>{t(`${tKey}.form.place.title`)}</p>
        <PlacePicker
          placeholder={`${t(`${tKey}.form.place.placeholder`)}`}
          onChange={onPlaceChange}
          name='place'
          value={place || ''}
          inputClass={classNames(classes.place_field, { [classes.field_error]: !validation.place })} 
        />
      </div>
      <div className={classes.field_wrap}>
        <p className={classes.name}>{t(`${tKey}.form.date`)}</p>
        <div className={classes.date_fields}>
          <Input
            autoComplete="off"
            type='number'
            name='month'
            value={data.month || ''}
            placeholder='MM'
            step="1" min="1" max="12"
            className={classNames(classes.date_field, { [classes.field_error]: !validation.month })}
            onChange={onInputChange}
          />
          <Input
            autoComplete="off"
            type='number'
            name='day'
            step="1" min="1" max="31"
            value={data.day || ''}
            placeholder='DD'
            className={classNames(classes.date_field, { [classes.field_error]: !validation.day })}
            onChange={onInputChange}
          />
          <Input
            autoComplete="off"
            type='number'
            name='year'
            value={data.year || ''}
            step="1" min="1900" max="2050"
            placeholder='YYYY'
            className={classNames(classes.date_field, { [classes.field_error]: !validation.year })}
            onChange={onInputChange}
          />
        </div>
      </div>
      <div className={classes.field_wrap}>
        <p className={classes.name}>{t(`${tKey}.form.time`)}</p>
        <div className={classes.time_fields}>
          <Input
            autoComplete="off"
            type='number'
            name='hours'
            value={data.hours || ''}
            step="1" min="0" max="23"
            placeholder='hh'
            className={classNames(classes.time_field, { [classes.field_error]: !validation.hours })}
            onChange={onInputChange}
          />
          <Input
            autoComplete="off"
            type='number'
            name='minutes'
            value={data.minutes || ''}
            step="1" min="0" max="59"
            placeholder='mm'
            className={classNames(classes.time_field, { [classes.field_error]: !validation.minutes })}
            onChange={onInputChange}
          />
        </div>
      </div>
    </form>
  )
}