import React from 'react';
import classNames from 'classnames';

import classes from './style.module.scss'

interface Props {
  text: string;
  className?: string;
}

export const OverlayTextBlock: React.FC<Props> = ({ text, className }) => {
  return (
    <>
      <div className={classNames(classes.cover, className)}/>
      <p className={classes.overlay_text}>{text}</p>
    </>
  )
}